.flower_leaf_12 .leaf-1 {
  position: absolute;
  top: 76px;
  left: 126px;
  transform: rotate(-60deg);
  z-index: 2;
}

.flower_leaf_12 .leaf-2 {
  position: absolute;
  top: 20px;
  left: 183px;
  transform: rotate(-30deg);
  z-index: 2;
}

.flower_leaf_12 .leaf-3 {
  z-index: 3;
  position: absolute;
  top: 0;
}

.flower_leaf_12 .leaf-4 {
  position: absolute;
  top: 20px;
  left: 337px;
  transform: rotate(30deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-5 {
  position: absolute;
  top: 75px;
  left: 395px;
  transform: rotate(60deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-6 {
  position: absolute;
  top: 152px;
  left: 414px;
  transform: rotate(90deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-7 {
  position: absolute;
  top: 230px;
  left: 396px;
  transform: rotate(120deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-8 {
  position: absolute;
  top: 273px;
  left: 327px;
  transform: rotate(150deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-9 {
  position: absolute;
  top: 308px;
  left: 261px;
  transform: rotate(180deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-10 {
  position: absolute;
  top: 287px;
  left: 184px;
  transform: rotate(-150deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-11 {
  position: absolute;
  top: 229px;
  left: 129px;
  transform: rotate(-120deg);
  z-index: 1;
}

.flower_leaf_12 .leaf-12 {
  position: absolute;
  top: 153px;
  left: 106px;
  transform: rotate(-90deg);
  z-index: 1;
}

.flower_leaf_12 {
  .leaf_text {
    p {
      height: 60px;
      width: 90px;
      overflow: hidden;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 60px;
      left: -10px;
      transform: rotate(60deg);
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 50px;
      left: 14px;
      transform: rotate(30deg);
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 40px;
      left: 20px;
      transform: rotate(0deg);
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 30px;
      left: 20px;
      transform: rotate(-30deg);
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 20px;
      left: 15px;
      transform: rotate(-60deg);
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 10px;
      left: 0px;
      transform: rotate(-90deg);
    }
  }
  .leaf-7 {
    .leaf_text {
      top: 20px;
      left: -20px;
      transform: rotate(-120deg);
    }
  }
  .leaf-8 {
    .leaf_text {
      top: 10px;
      left: -30px;
      transform: rotate(-150deg);
    }
  }
  .leaf-9 {
    .leaf_text {
      top: 30px;
      left: -35px;
      transform: rotate(-180deg);
    }
  }
  .leaf-10 {
    .leaf_text {
      top: 35px;
      left: -35px;
      transform: rotate(150deg);
    }
  }
  .leaf-11 {
    .leaf_text {
      top: 50px;
      left: -30px;
      transform: rotate(120deg);
    }
  }
  .leaf-12 {
    .leaf_text {
      top: 70px;
      left: -15px;
      transform: rotate(90deg);
    }
  }
}
