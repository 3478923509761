.add_user_form {
    &.page_center {
        .center_head {
            border-bottom: 1px solid #E4E4E5;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }

    .location_photo {
        border-bottom: 1px solid #E4E4E5;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .location_image {
        margin-bottom: 0;
    }
    .center_all{
        border: 1px solid #F1F2F2;
        border-radius: 24px;
        background-color: #fff;
    }
}


.user_info{
    .fp_layout_inner{
        .cardHead{
            display: none;
        }
    }
}

.user_info_button {
    padding: 0;
    background: transparent;
    height: 40px;
    display: block;

    &:hover {
        background: transparent !important;
    }
}

.label_text {
    font-weight: 500;
}