.flower_leaf_3 {
  .leaf_text {
    flex-direction: column;
    p {
      height: 72px;
      overflow: hidden;
    }
  }

  .leaf-1 {
    z-index: 3;
    position: absolute;
    top: 40px;

    .leaf_text {
      left: 90px;
      top: 70px;
    }

    .leaf_number {
      margin-left: auto;
      margin-right: auto;
      margin-top: 5px;
    }
  }

  .leaf-2 {
    position: absolute;
    bottom: 47px;
    right: 44px;
    z-index: 2;

    .petal_svg {
      transform: rotate(120deg);
    }

    .leaf_text {
      left: 100px;
      top: 120px;
    }

    .leaf_number {
      margin-bottom: 5px;
      order: 1;
    }

    .leaf_detail {
      order: 2;
    }
  }

  .leaf-3 {
    position: absolute;
    bottom: 47px;
    left: 43px;
    z-index: 1;

    .petal_svg {
      transform: rotate(-120deg);
    }

    .leaf_text {
      left: 90px;
      top: 120px;
    }

    .leaf_number {
      margin-left: auto;
      margin-bottom: 5px;
      margin-right: 25px;
      order: 1;
    }
    .leaf_detail {
      order: 2;
    }
  }
}
