.column_wrapper {
  border: 1px solid #F4F4F9;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.column_header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  .column_num {
    border-radius: 6px;
    padding: 2px;
    background-color: #FEF5F1;
    color: #C84710;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .column_title {
    color: #070707;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  button {
    padding: 0;
    background: transparent;
    border: 0;
    margin-left: auto;
    cursor: pointer;

    svg {
      width: 20px;
    }
  }
}



.drag_item {
  border: 1px dashed #D9DBE6;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #4C4D51;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}




.addPetal-btn {
  width: 100%;
  height: 109px;
  border-radius: 16px;
  background: #EFF0F5;
  border: 1px dashed #B3B5BC;
  color: #161616;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: none;

  &:hover {
    background-color: #fff;
    color: #161616 !important;
    border: 1px dashed #B3B5BC !important;
  }
}

.kanban_board {
  height: calc(100vh - 128px);
  overflow: hidden;

  >.ant-row,
  >.ant-row .ant-col {
    height: 100%;
  }
}

.basket_card {
  border-radius: 20px;
  height: 100%;
  overflow: hidden;
  
  .basket_header {
    margin-bottom: 16px;

    h4 {
      color: #161616;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 4px;
  
      span {
        color: #8558EA;
      }
    }
  
    p {
      color: #161616;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .basket_column_wrapper {
    border: 1px solid #ECEDF3;
    border-radius: 16px 16px 0 0;
    padding: 12px 16px 16px;

    .basket_role_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      h6 {
        color: #161616;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
      }
    
      .basket_add_role {
        .ant-btn {
          padding: 4px;
          color: #161616;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          gap: 6px;

          .anticon {
            svg {
              path {
                fill: currentColor;
              }
            }
          }
        }
      }
    }

    .basket_column_content {
      height: calc(100vh - 384px);
      overflow: hidden;
      overflow-y: auto;
      
    }
  }

  .steps_footer {
    background-color: #fff;
    z-index: 9;
  }
}

.role_right_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 16px;
  display: inline-block;

  .role_right_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 5px 0;

    h6 {
      color: #070707;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 0;
      font-family: "ESRebondGrotesque-Semibold";
    }

    .facilitator_details {
      background-color: #fff;
      border-radius: 10px;
      padding: 6px 12px;
      display: flex;
      align-items: center;

      h4 {
        color: #070707;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      p {
        margin-left: 8px;
        color: #4C4D51;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }

      .participant_avatar {
        .ant-avatar {
          width: 24px;
          height: 24px;
          border: 1px solid #e8e9f0;
          background-color: #f3f4f8;
          color: #161616;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.role_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: #fff;

  &.is_dragging {
    height: 52px;
  }

  .role_dragger {
    cursor: grab;
    width: 16px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .role_content {
    width: calc(100% - 36px);
    padding: 0 8px;
    display: flex;
    align-items: center;

    textarea{
      width: 100%;
      line-height: normal;
      border-radius: 8px;
      padding: 6px 4px;
      font-size: 14px;
      margin-left: 6px;
      line-height: 20px;
      min-height: 28px;
    }

    .role_image {
      .ant-avatar {
        width: 20px;
        height: 20px;
        border: 1px solid #E8E9F0;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }

    p {
      color: #161616;
      font-size: 14px;
      font-weight: 300;
      width: calc(100% - 28px);
      word-wrap: break-word;
      padding: 6px 10px;
      line-height: 20px;
      border: 1px solid #fff;
      margin-bottom: 0;
    }
  }

  .role_btn {
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .role_btn {
      opacity: 1;
      visibility: visible;
    }
  }
}

.is_dragging {
  opacity: 0.4;
  border: 1px solid rosybrown;
  border-radius: 8px;
}

.column_petal_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .petal_box {
    width: calc(50% - 8px);
  }
}