.ant-layout-header {
  background-color: #f7f8fa;
  height: auto;
  padding: 16px 24px;
  .ant-menu {
    background-color: #f7f8fa;
    display: flex;
    align-items: center;
    width: 500px;
    border: 0;
    margin-left: 24px;

    .ant-menu-item {
      padding: 0;

      &:after {
        display: none;
      }

      .ant-menu-title-content {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          height: 40px;
          color: #161616;
          font-weight: 500;
          font-size: 16px;
          line-height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 10px;
        }
      }
    }
    .ant-menu-item-selected {
      border-radius: 10px;
      background-color: #161616;
      color: #fff;
      &:hover {
        background-color: #161616c7;
        border-radius: 10px;
        color: #fff;
      }
      .ant-menu-title-content{
        border-radius: 10px;
        background-color: #161616;
        color: #fff;
        a{
          color: #fff;
        }
      }
    }
  }
  .ant-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100px;
    .ant-image-img {
      padding: 0px;
    }
  }

  .user_info {
    background-color: #e8e9f0;
    border-radius: 10px;
    padding: 2px 2px 2px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .notificationButton {
      width: 40px;
      height: 36px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      .ant-image {
        height: auto;
        width: auto;
        .ant-image-img {
          height: 20px;
          width: 20px;
        }
      }
    }
    .userName {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-size: 16px;
      font-weight: 400;
      color: #1c1c1c;

      .ant-image {
        img {
          border-radius: 8px;
          object-fit: cover;
        }
      }
    }

    .table_with_image .ant-avatar {
      font-size: 14px !important;
    }
  }
}


.secondary_header{
  margin: -30px -30px 24px -30px;
  padding: 20px 30px;
  background-color: #f7f8fa;
  display: flex;
  gap: 20px;

  .detail_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right_top_job{
      width: 500px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
    .jobName{
      font-size: 32px;
      color: #1C1C1C;
      font-weight: 600;
    }
  }
}

.top_header_links {
  padding-left: 24px;
  display: flex;
  align-items: center;
  gap: 16px;

  .icon_white_btn {
    background-color: transparent;
    border: 1px solid #f7f8fa;

    &:hover {
      background-color: #E8E9F0 !important;
      border: 1px solid #E8E9F0;
    }
  }
}

.filter_btns{
  .top_filter{
    .filter_right{
      .filter_btn{
        .ant-select{
          width: auto;
          min-width: 80px;
          &:hover{
            .ant-select-clear{
              background-color: #fff; 
              width: auto;
              height: auto;
              margin-top: -8px;
              svg{
                path{
                  fill: #000;
                }
              }
            }
          }
        }
        .ant-select-item-option-selected{
          gap: 10px;
        }
      }
    }
  }
}