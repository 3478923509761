.ant-layout {
    background: transparent;
    .ant-layout-sider {
        &.dashboard_sidebar {
            background: #0D1019;
            position: fixed;
            left: 0;
            top: 0;
            padding: 24px 16px;
            height: 100vh;
            z-index: 9;            
            .sidebar_menu{
                background-color: transparent;
                li.ant-menu-item.ant-menu-item-selected{
                    background: #FFFFFF1F;
                    color: #fff !important;
                    .ant-menu-item-icon{
                        svg{
                            path{
                                fill: #FFD96C;
                            }
                        }
                    }
                }
                li{
                    color: #AEAFB2 !important;
                    padding: 8px 12px !important;
                    margin: 0px 0px 20px 0px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: "DM Sans", sans-serif;
                    &:hover{
                        background: #FFFFFF1F;
                        color: #AEAFB2 !important;
                        .ant-menu-item-icon{
                            svg{
                                path{
                                    fill: #FFD96C;
                                }
                            }
                        }
                    }
                    .ant-menu-item-icon{
                        svg{
                            path{
                                fill: #AEAFB2;
                            }
                        }
                    }
                }
            }
        }

        .sidebar_logo {
            display: flex;
            align-items: center;
            margin-bottom: 37px;
            .logo_bg{
                display: flex;
            }
            .logo_text {
                padding-left: 16px;

                h4 {
                    color: #fff;
                    font-size: 20px;
                    font-family: "DM Sans", sans-serif;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 2px;
                }

                p {
                    color: #A9AAC1;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: "DM Sans", sans-serif;
                    line-height: 16px;
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }

        .sidebar_footer{
            width: 100%;
            position: absolute;
            left: 0;
            padding: 16px 16px 14px 16px;
            height: 90px;
            background-color: transparent;
            border-top: 1px solid #484B53;
            .user_info{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                .parent{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    .logo_bg{
                        display: flex;
                        border: 1px solid #ccc;
                        border-radius: 100px;
                        background: #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        img{
                            border-radius: 100px;
                            display: block;
                            object-fit: cover;
                            width: 100%;
                            height: 40px;
                        }
                    }
                    .logo_text {
                        padding-left: 12px;
                        width: calc(100% - 40px);
        
                        h4 {
                            color: #fff;
                            font-size: 16px;
                            font-weight: 300;
                            font-family: "DM Sans", sans-serif;
                            line-height: 22px;
                            margin-bottom: 0px;
                            text-align: left;
                        }
        
                        p {
                            color: #AEAFB2;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 0;
                            font-weight: 300;
                            font-family: "DM Sans", sans-serif;
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: left;
                        }
                    }
                }
                .anticon{
                    svg{
                        width: 15.75px;
                        height: 3px;
                        position: absolute;
                        top: 35px;
                        right: 16px;
                    }
                }
            }
        }
    }
}

// Arete main layout
.main_layout {
    background: #F3F4F8;

    .content_area {
        padding: 24px;
        border-radius: 32px 32px 0 0;
        overflow: hidden;
        background: #fff;
        min-height: calc(100vh - 72px);
        .page_title{
            h3{
                color: #0D1019;
                font-size: 24px;
                line-height: 28px;
                font-family: "DM Sans", sans-serif;
                font-weight: 500;
            }
        }
        .fp_main_details{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .companyInfo{
                .ant-card-meta{
                    display: flex;
                    gap: 16px;

                    .ant-image {
                        border-radius: 16px;
                        border: 1px solid #EFF0F5;
                        overflow: hidden;
                        img{
                            object-fit: cover;
                        }
                    }
                }
            }
            .actionBtn{
                display: flex;
                gap: 13px;
            }
        }

        // Dashboard Tabs
        .dashboard_Tabs_wrapper {
            padding: 24px 20px;
            border: 1px solid #EEEEF5;
            border-radius: 20px;
            .ant-tabs-content-holder{
                height: 288px;
                overflow: auto;
                .ant-list-item{
                    padding-top: 16px;
                    padding-bottom: 16px;
                    border-block-end: 1px solid #EEEEF5 !important;
                    .ant-list-item-meta{
                        .ant-list-item-meta-avatar{
                            .ant-avatar-image{
                                width: 36px;
                                height: 36px;
                            }
                        }
                        .ant-list-item-meta-content{
                            .ant-list-item-meta-title{
                                
                                font-weight: 500;
                                color: #050622;
                                a{
                                    font-family: "DM Sans", sans-serif;
                                    font-weight: 500;
                                    color: #050622;
                                    &:hover{
                                        color: #050622;
                                    }
                                }
                            }
                            .ant-list-item-meta-description{
                                color: #050622;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                                font-family: "DM Sans", sans-serif;                                
                            }
                        }
                    }
                    .notification_time{
                        font-size: 12px;
                        line-height: 16px;
                        font-family: "DM Sans", sans-serif;
                        font-weight: 500;
                        color: #6A6B8A;
                        
                    }
                }
                .ant-tabs-content{
                    .ant-col{
                        padding-left: 0px !important;
                        padding-right: 20px !important;
                    }
                }
            }
            .ant-tabs-nav-wrap{
                .ant-tabs-nav-list{
                    background-color: rgb(247, 248, 251);
                    width: 90%;
                    border-radius: 8px;
                    padding: 2px;
                    border: unset;
                    margin-left: auto;
                    .ant-tabs-tab{
                        margin: 0;
                        padding: 8px 12px;
                        width: 140px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .ant-tabs-tab-btn{
                            color: #6A6B8A;
                            font-family: "DM Sans", sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            text-shadow: unset;
                        }
                    }
                    .ant-tabs-tab-active{
                        background-color: #fff;
                        border-radius: 8px;
                        width: 140px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 12px;                        
                        .ant-tabs-tab-btn{
                            color: #050622;
                            font-family: "DM Sans", sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            text-shadow: unset;
                        }
                    }
                }
            }
            .tabs_title{
                position: absolute;
                top: 31px;
                font-size: 22px;
                font-family: "DM Sans", sans-serif;
                font-weight: 500;
            }
            .ant-tabs{
                .ant-tabs-nav-more{
                    display: none;
                }
                .ant-tabs-nav{
                    &::before{
                        border-bottom: unset !important;
                        content: '';
                    }
                }
            }
        }
    }
    .seconday_header{
        height: 124px;
        padding: 20px 24px;
        background-color: #f7f8fa;
    }
}

