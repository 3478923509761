.flower_leaf_6 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 79px;
  left: 60px;
  transform: rotate(-60deg);
}

.flower_leaf_6 .leaf-2 {
  position: absolute;
  top: 0;
  z-index: 2;
}

.flower_leaf_6 .leaf-3 {
  position: absolute;
  top: 79px;
  left: 336px;
  transform: rotate(60deg);
  z-index: 1;
}

.flower_leaf_6 .leaf-4 {
  position: absolute;
  top: 237px;
  left: 337px;
  transform: rotate(120deg);
  z-index: 1;
}

.flower_leaf_6 .leaf-5 {
  position: absolute;
  top: 314px;
  left: 198px;
  transform: rotate(-180deg);
  z-index: 1;
}

.flower_leaf_6 .leaf-6 {
  position: absolute;
  top: 237px;
  left: 60px;
  transform: rotate(-120deg);
  z-index: 1;
}

.flower_leaf_6 {
  .leaf_text {
    p {
      height: 72px;
      overflow: hidden;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 80px;
      left: 50px;
      transform: rotate(60deg);
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 75px;
      left: 53px;
      transform: rotate(0deg);
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 80px;
      left: 70px;
      transform: rotate(-60deg);
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 80px;
      left: 45px;
      transform: rotate(-120deg);
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 80px;
      left: 50px;
      transform: rotate(180deg);
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 80px;
      left: 40px;
      transform: rotate(120deg);
    }
  }
}
