.flower_leaf_18 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 150px;
  left: 60px;
  transform: rotate(0deg);
}

.flower_leaf_18 .leaf-2 {
  position: absolute;
  top: 110px;
  left: 90px;
  transform: rotate(20deg);
  z-index: 2;
}

.flower_leaf_18 .leaf-3 {
  position: absolute;
  top: 90px;
  left: 138px;
  transform: rotate(39deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-4 {
  position: absolute;
  top: 80px;
  left: 189px;
  transform: rotate(59deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-5 {
  position: absolute;
  top: 90px;
  left: 238px;
  transform: rotate(80deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-6 {
  position: absolute;
  top: 110px;
  left: 287px;
  transform: rotate(100deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-7 {
  position: absolute;
  top: 150px;
  left: 320px;
  transform: rotate(120deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-8 {
  position: absolute;
  top: 202px;
  left: 330px;
  transform: rotate(140deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-9 {
  position: absolute;
  top: 254px;
  left: 340px;
  transform: rotate(-200deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-10 {
  position: absolute;
  top: 300px;
  left: 315px;
  transform: rotate(-180deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-11 {
  position: absolute;
  top: 330px;
  left: 270px;
  transform: rotate(-160deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-12 {
  position: absolute;
  top: 360px;
  left: 226px;
  transform: rotate(-140deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-13 {
  position: absolute;
  top: 370px;
  left: 174px;
  transform: rotate(-120deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-14 {
  position: absolute;
  top: 365px;
  left: 122px;
  transform: rotate(-100deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-15 {
  position: absolute;
  top: 340px;
  left: 76px;
  transform: rotate(-80deg);
  z-index: 1;
}

.flower_leaf_18 .leaf-16 {
  position: absolute;
  top: 298px;
  left: 45px;
  transform: rotate(-60deg);
  z-index: 1;
}
.flower_leaf_18 .leaf-17 {
  position: absolute;
  top: 248px;
  left: 30px;
  transform: rotate(-40deg);
  z-index: 1;
}
.flower_leaf_18 .leaf-18 {
  position: absolute;
  top: 195px;
  left: 30px;
  transform: rotate(-20deg);
  z-index: 1;
}

.flower_leaf_18 {
  .leaf_text {
    p {
      display: none;
    }
    h4 {
      font-size: 12px;
      width: max-content;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 45px;
      left: 40px;
      transform: rotate(210deg);
    }
  }
  .leaf-7 {
    .leaf_text {
      top: 45px;
      left: 40px;
      transform: rotate(210deg);
    }
  }
  .leaf-8 {
    .leaf_text {
      top: 40px;
      left: 35px;
      transform: rotate(210deg);
    }
  }
  .leaf-9 {
    .leaf_text {
      top: 50px;
      left: 48px;
      transform: rotate(210deg);
    }
  }
  .leaf-10 {
    .leaf_text {
      top: 35px;
      left: 28px;
      transform: rotate(210deg);
    }
  }
  .leaf-11 {
    .leaf_text {
      top: 30px;
      left: 15px;
      transform: rotate(210deg);
    }
  }
  .leaf-12 {
    .leaf_text {
      top: 30px;
      left: 15px;
      transform: rotate(210deg);
    }
  }
  .leaf-13 {
    .leaf_text {
      top: 35px;
      left: 25px;
      transform: rotate(210deg);
    }
  }
  .leaf-14 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }
  .leaf-15 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }
  .leaf-16 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }
  .leaf-17 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }
  .leaf-18 {
    .leaf_text {
      top: 60px;
      left: 25px;
      transform: rotate(30deg);
    }
  }
}