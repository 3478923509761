.flower_leaf_14 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 60px;
  left: 143px;
  transform: rotate(-52deg);
}

.flower_leaf_14 .leaf-2 {
  position: absolute;
  top: 16px;
  left: 196px;
  transform: rotate(-26deg);
  z-index: 2;
}

.flower_leaf_14 .leaf-3 {
  position: absolute;
  top: 0;
  left: 263px;
  z-index: 1;
}

.flower_leaf_14 .leaf-4 {
  position: absolute;
  top: 16px;
  left: 330px;
  transform: rotate(26deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-5 {
  position: absolute;
  top: 60px;
  left: 383px;
  transform: rotate(52deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-6 {
  position: absolute;
  top: 123px;
  left: 413px;
  transform: rotate(78deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-7 {
  position: absolute;
  top: 190px;
  left: 416px;
  transform: rotate(-257deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-8 {
  position: absolute;
  top: 246px;
  left: 376px;
  transform: rotate(-231deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-9 {
  position: absolute;
  top: 291px;
  left: 334px;
  transform: rotate(-208deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-10 {
  position: absolute;
  top: 308px;
  left: 268px;
  transform: rotate(-182deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-11 {
  position: absolute;
  top: 295px;
  left: 201px;
  transform: rotate(-156deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-12 {
  position: absolute;
  top: 250px;
  left: 150px;
  transform: rotate(-130deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-13 {
  position: absolute;
  top: 191px;
  left: 115px;
  transform: rotate(-104deg);
  z-index: 1;
}

.flower_leaf_14 .leaf-14 {
  position: absolute;
  top: 123px;
  left: 114px;
  transform: rotate(-78deg);
  z-index: 1;
}

.flower_leaf_14 {
  .leaf_text {
    p {
      display: none;
    }
    h4 {
      width: 80px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 90px;
      left: -10px;
      transform: rotate(52deg);
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 70px;
      left: 10px;
      transform: rotate(25deg);
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 50px;
      left: 15px;
      transform: rotate(0deg);
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 40px;
      left: 10px;
      transform: rotate(-25deg);
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 40px;
      left: 5px;
      transform: rotate(-52deg);
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 20px;
      left: 0px;
      transform: rotate(-78deg);
    }
  }
  .leaf-7 {
    .leaf_text {
      top: 20px;
      left: -20px;
      transform: rotate(-103deg);
    }
  }
  .leaf-8 {
    .leaf_text {
      top: 10px;
      left: -30px;
      transform: rotate(-129deg);
    }
  }
  .leaf-9 {
    .leaf_text {
      top: 20px;
      left: -35px;
      transform: rotate(-152deg);
    }
  }
  .leaf-10 {
    .leaf_text {
      top: 25px;
      left: -45px;
      transform: rotate(182deg);
    }
  }
  .leaf-11 {
    .leaf_text {
      top: 40px;
      left: -50px;
      transform: rotate(156deg);
    }
  }
  .leaf-12 {
    .leaf_text {
      top: 50px;
      left: -50px;
      transform: rotate(130deg);
    }
  }
  .leaf-13 {
    .leaf_text {
      top: 70px;
      left: -35px;
      transform: rotate(104deg);
    }
  }
  .leaf-14 {
    .leaf_text {
      top: 70px;
      left: -20px;
      transform: rotate(78deg);
    }
  }
}
