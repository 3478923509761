.editJob {
  display: flex;
  gap: 16px;

  .only_jobs {
    .ant-tabs.ant-tabs-left .ant-tabs-nav {
      padding: 32px 24px 24px 24px;
    }
  }

  .ant-tabs.ant-tabs-left .ant-tabs-nav {
    padding: 60px 24px 24px 24px;
    width: 25%;
    .ant-tabs-tab-btn {
      .tab-label {
        gap: 8px;
        .tab-number {
          height: 20px;
          width: 20px;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .ant-tabs-tab-btn {
      width: 100%;
      // text-align: left;
    }

    .job_name {
      padding: 10px 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }

    .tab-label {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border: 1px solid #eff0f5;
      border-radius: 10px;

      .ant-image {
        margin-left: auto;
      }
    }
    .ant-tabs-tab {
      padding: 0;
      &:nth-child(1) {
        margin-bottom: 16px;
        .tab-number {
          background-color: #e8fafb;
          color: #047e82;
        }
      }
      &:nth-child(2) {
        .tab-number {
          background-color: #fef5f1;
          color: #c84710;
        }
      }
      &:nth-child(3) {
        .tab-number {
          background-color: #fff0fa;
          color: #cb2c8b;
        }
      }
      &:nth-child(4) {
        .tab-number {
          background-color: #f6f3ff;
          color: #8558ea;
        }
      }
      &:nth-child(5) {
        .tab-number {
          background-color: #effdf0;
          color: #448009;
        }
      }
      &:nth-child(6) {
        .tab-number {
          background-color: #fef6e7;
          color: #a56110;
        }
      }
      &:nth-child(7) {
        .tab-number {
          background-color: #fdefef;
          color: #d33333;
        }
      }
      &:nth-child(8) {
        .tab-number {
          background-color: #fff0ff;
          color: #c225b5;
        }
      }
    }
  }

  .controlPanel {
    width: 100%;
    position: relative;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      position: absolute;
      width: 25%;
      z-index: 9;
      top: 0;
      left: 0;
      padding: 24px 24px 0;

      h6 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #171718;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        cursor: pointer;

        .PlusIcon {
          svg {
            path {
              fill: #161616;
            }
          }
        }
      }
    }

    .roleAdd {
      ul {
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #161616;
          gap: 8px;
          border: 1px solid #eff0f5;
          padding: 10px;
          border-radius: 10px;
          margin-bottom: 16px;

          .count {
            font-size: 12px;
            font-weight: 500;
            color: #8558ea;
            background-color: #f1f0fd;
            border-radius: 5px;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .contentPanel {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 24px 44px;
    border-radius: 16px;
    // height: calc(100vh - 120px);
    .title_bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 50px;
      margin-bottom: 24px;
      .mainTitle {
        display: flex;
        h4 {
          font-size: 18px;
          font-weight: 600;
          color: #161616;
          margin-bottom: 4px;
        }
        p {
          font-size: 16px;
          font-weight: 300;
          color: #161616;
          margin-bottom: 10px;
        }
      }
      .formLink {
        font-size: 14px;
        font-weight: 600;
        color: #161616;
        display: flex;
        align-items: center;
      }
    }
    .ant-form {
      h4 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        font-family: "DM Sans";
        margin-bottom: 4px;
      }
    }
  }
}

.job_card {
  padding: 24px 48px;
  background-color: #fff;
  border-radius: 20px;
}

.auto_height {
  .ant-select-selector {
    height: auto !important;
  }
}

.ant-tabs-tab-active {
  .tab-label {
    border: 1px solid #070707;
  }

  .jobName-icon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.jobTitle {
  text-align: left;
  margin-top: 16px;
}

.jobName-icon {
  svg {
    path {
      fill: #070707;
    }
  }
}

.button_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.role_card {
  .ant-card-body {
    padding: 0;
  }
}