.event_card_div {
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }

    h5{
        font-size: 16px;
        font-family: "DM Sans", sans-serif;
        font-weight: 300;
        line-height: 22px;
        color: #0D1019;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .ant-tag {
            background-color: #F1FDF5;
            color: #0D1019;
            margin-left: 4px;
            border: 0;
            border-radius: 6px;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            width: 22px;
            height: 22px;
            font-family: "DM Sans", sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;

            &.past_tag {
                background-color: #F1F2F2;
            }
        }
    }
}

.eventCard {
    border: 1px solid #E4E4E5;
    border-radius: 16px;
    margin-bottom: 12px;

    .ant-card-body {
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
    }

    &.upcoming {
        .event_date {
            color: #3EA561;
            background-color: #F1FDF5;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 12px;
            .dateFld{
                color: #3EA561;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                font-family: "DM Sans", sans-serif;
            }
        }
    }

    .event_date {
        border-right: 1px solid #E4E4E5;
        padding: 12px;
        text-align: center;
        width: 120px;
        height: 95px;
        background-color: #F1F2F2;
        border-radius: 16px 0 0 16px;
        color: #0D1019;
        font-weight: 400;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        line-height: 32px; 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;      

        span {
            width: 100%;
            display: block;
            color: #0D1019;
            font-weight: 400;
            font-family: "DM Sans", sans-serif;
            font-size: 12px;
            line-height: 14px;
        }
        .dateFld{
            color: #0D1019;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            font-family: "DM Sans", sans-serif;
        }
        .timeFld{
            color: #0D1019;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            font-family: "DM Sans", sans-serif;
        }
    }

    .event_details {
        padding: 14px 16px;

        h4 {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            a {
                color: #0D1019;
                font-weight: 300;
                font-family: "DM Sans", sans-serif;
                font-size: 20px;
                line-height: 24px;
                margin-right: 4px;
                display: flex;
                align-items: center;
                gap: 4px;
                text-transform: capitalize;
            }
            .ant-tag{
                border-radius: 40px;
                border: 1px solid #DF2E25;
                color: #DF2E25;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-transform: capitalize;
                padding: 3px 17px;
                background-color: #fff !important;
                font-family: "DM Sans", sans-serif;
                font-weight: 400;
            }
        }

        .event_loc_cat {
            display: flex;
            align-items: center;

            .ant-tag {
                border: 1px solid #0D1019;
                padding: 3px 8px 2px;
                border-radius: 100px;
                color: #0D1019;
                font-weight: 300;
                font-family: "DM Sans", sans-serif;
                font-size: 14px;
                line-height: 18px;
            }
        }

        .event_location {
            display: flex;
            align-items: center;
            color: #0D1019;
            font-weight: 300;
            font-family: "DM Sans", sans-serif;
            font-size: 14px;
            line-height: 20px;
            gap: 6px;
            margin-left: 8px;

            img {
                display: block;
                margin-right: 0px;
            }
        }
    }

    .event_amount {
        margin-left: auto;
        padding-right: 20px;
        color: #0D1019;
        font-weight: 300;
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        line-height: 24px;
    }
}


.member_form{
    .welcomeCard{
        border: 0;
        margin-bottom: 24px;
        margin-top: 14px;

        .ant-alert-with-description {
            align-items: center;
        }
        
        .ant-alert {
            padding: 12px 16px;
            background: #FAF3FF;
            border: 0;
            border-radius: 12px;

            .ant-alert-with-description .ant-alert-icon {
                margin-inline-end: 8px;
            }
        }

        .ant-alert-message {
            color: #0D1019;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 4px;
            font-family: "DM Sans", sans-serif;
        }

        .ant-alert-description {
            color: #0D1019;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            font-family: "DM Sans", sans-serif;
        }

        .ant-alert-close-icon {
            padding: 12px;
            margin-inline-start: 0;

            svg {
                path {
                    fill: #0D1019;
                }
            }
        }


        .ant-card-body{
            border-radius: 12px;
            padding: 12px 16px;
        }
        .ant-card-meta-detail{
            .ant-card-meta-title{
                font-size: 16px;
                line-height: 20px;
                // font-family: "DM Sans", sans-serif;
                // font-weight: 500;
                color: #050622;
                margin-bottom: 4px;
            }
            .ant-card-meta-description{
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                font-family: "DM Sans", sans-serif;
                color: #0D1019;
            }
        }
    }
    .center_all{
        border: 1px solid #F1F2F2;
        border-radius: 24px;
        background-color: #fff;
    }
}

.members_form_area{
    h4{
        font-size: 16px;
        line-height: 20px;
        color: #0D1019;
        font-family: "DM Sans", sans-serif;
        font-weight: 500;
        margin-bottom: 12px;
    }
}

.member_company_detail {
    h4 {
        font-size: 20px;
        line-height: 24px;
        color: #0D1019;
        font-family: "DM Sans", sans-serif;
        font-weight: 500;
        margin-bottom: 20px;
    }
    h5 {
        font-size: 14px;
        line-height: 20px;
        color: #A25EDC;
        font-family: "DM Sans", sans-serif;
        margin-bottom: 4px;
        font-weight: 300;

        .ant-image {
            margin-right: 4px;
        }
    }
    p {
        font-size: 16px;
        line-height: 22px;
        color: #0D1019;
        font-family: "DM Sans", sans-serif;
        margin-bottom: 0;
    }
}

.members_detail_left_card {
    .ant-descriptions-item-label {
        p {
            font-size: 14px;
            line-height: 20px;
            color: #A25EDC;
            font-family: "DM Sans", sans-serif;
            margin-bottom: 4px;
            font-weight: 300;

            .ant-image {
                margin-right: 4px;
            }
        }
    }
    .ant-descriptions-item-content {
        ul {
            li{
                font-size: 16px;
                line-height: 22px;
                color: #0D1019;
                font-family: "DM Sans", sans-serif;
                margin-bottom: 8px;
                font-weight: 300;

                &::marker {
                    color: #A25EDC;
                    font-size: 23px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.upload_modal_preview {
    display: flex;
    justify-content: center;

    .photo_wrapper {
        width: 172px;
        position: relative;
    }

    .delete_photo {
        padding: 0;
        border: 0;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0px;
        bottom: 0px;
        background: transparent;

        &:hover {
            background: transparent !important;
        }

        img{
            background-color: #F3F4F8;
            padding: 5px;
            border-radius: 5px !important;
        }
    }
    
}

.ant-tag {
    text-transform: capitalize;
}