.leaf_detail {
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

// 1, 2 & 3 Petals flower
.finalizedFlower {
  .flower_leaf_3 {
    .leaf-1 {
      .leaf_text {
        top: 70px;
        left: 90px;
        .leaf_number {
          position: absolute;
          bottom: -30px;
          right: 50px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 150px;
        left: 90px;
        .leaf_number {
          position: absolute;
          top: -30px;
          right: 100px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 150px;
        left: 80px;
        .leaf_number {
          position: absolute;
          top: -35px;
          right: -30px;
        }
      }
    }
  }
}

// 3 & 4 Petals flower
.finalizedFlower {
  .flower_leaf_4 {
    .leaf-1 {
      .leaf_text {
        top: 70px;
        left: 75px;
        .leaf_number {
          position: absolute;
          bottom: -70px;
          right: 50px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 70px;
        left: 75px;
        .leaf_number {
          position: absolute;
          top: 30px;
          right: 130px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 80px;
        left: 80px;
        .leaf_number {
          position: absolute;
          top: -60px;
          right: 50px;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 75px;
        left: 75px;
        .leaf_number {
          position: absolute;
          bottom: 25px;
          left: 125px;
        }
      }
    }
  }
}

// 5 & 6 Petals flower
.finalizedFlower {
  .flower_leaf_6 {
    .leaf-1 {
      .leaf_text {
        top: 80px;
        left: 70px;
        .leaf_number {
          position: absolute;
          bottom: -40px;
          right: -20px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 80px;
        left: 65px;
        .leaf_number {
          position: absolute;
          bottom: -60px;
          right: 50px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 70px;
        left: 40px;
        .leaf_number {
          position: absolute;
          top: 115px;
          right: 122px;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 85px;
        left: 60px;
        .leaf_number {
          position: absolute;
          bottom: 70px;
          left: -35px;
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        top: 80px;
        left: 65px;
        .leaf_number {
          position: absolute;
          top: -90px;
          left: 50px;
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        top: 80px;
        left: 50px;
        .leaf_number {
          position: absolute;
          top: -35px;
          left: 125px;
        }
      }
    }
  }
}

// 7 & 8 Petals flower
.finalizedFlower {
  .flower_leaf_8 {
    .leaf-1 {
      .leaf_text {
        top: 65px;
        left: 40px;
        .leaf_number {
          position: absolute;
          bottom: -40px;
          right: -15px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 70px;
        left: 40px;
        .leaf_number {
          position: absolute;
          bottom: -70px;
          right: 50px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 60px;
        left: 40px;
        .leaf_number {
          position: absolute;
          top: 100px;
          right: 115px;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 80px;
        left: 40px;
        .leaf_number {
          position: absolute;
          bottom: 25px;
          left: -50px;
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        top: 70px;
        left: 40px;
        .leaf_number {
          position: absolute;
          top: -47px;
          left: -23px;
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        top: 70px;
        left: 40px;
        .leaf_number {
          position: absolute;
          top: -100px;
          left: 50px;
        }
      }
    }
    .leaf-7 {
      .leaf_text {
        top: 80px;
        left: 45px;
        .leaf_number {
          position: absolute;
          bottom: 95px;
          left: 120px;
        }
      }
    }
    .leaf-8 {
      .leaf_text {
        top: 80px;
        left: 45px;
        .leaf_number {
          position: absolute;
          bottom: 20px;
          right: -50px;
        }
      }
    }
  }
}

// 9 & 10 Petals flower
.finalizedFlower {
  .flower_leaf_10 {
    .leaf_text {
      width: 100px;
    }
    .leaf-1 {
      .leaf_text {
        top: 55px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: 70px;
          right: -70px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 50px;
        left: 25px;
        .leaf_number {
          position: absolute;
          top: 120px;
          left: 97px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 50px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: 145px;
          right: 40px;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 50px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: 120px;
          left: -20px;
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        top: 70px;
        left: 25px;
        .leaf_number {
          position: absolute;
          top: 60px;
          left: -50px;
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        top: 50px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: 5px;
          left: -80px;
        }
      }
    }
    .leaf-7 {
      .leaf_text {
        top: 60px;
        left: 20px;
        .leaf_number {
          position: absolute;
          bottom: 120px;
          left: -25px;
        }
      }
    }
    .leaf-8 {
      .leaf_text {
        top: 60px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: -102px;
          right: 40px;
        }
      }
    }
    .leaf-9 {
      .leaf_text {
        top: 45px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: -80px;
          right: -30px;
        }
      }
    }
    .leaf-10 {
      .leaf_text {
        top: 55px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: -12px;
          right: -62px;
        }
      }
    }
  }
}

// 11 & 12 Petals flower
.finalizedFlower {
  .flower_leaf_12 {
    .leaf_text {
      width: 80px;
    }
    .leaf-1 {
      .leaf_text {
        top: 50px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: 85px;
          right: -70px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 50px;
        left: 23px;
        .leaf_number {
          position: absolute;
          top: 125px;
          left: 80px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 45px;
        left: 22px;
        .leaf_number {
          position: absolute;
          top: 155px;
          right: 30px;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 40px;
        left: 22px;
        .leaf_number {
          position: absolute;
          top: 135px;
          left: -30px;
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        top: 40px;
        left: 22px;
        .leaf_number {
          position: absolute;
          top: 95px;
          left: -80px;
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        top: 50px;
        left: 23px;
        .leaf_number {
          position: absolute;
          top: 28px;
          left: -97px;
        }
      }
    }
    .leaf-7 {
      .leaf_text {
        top: 50px;
        left: 20px;
        .leaf_number {
          position: absolute;
          bottom: 89px;
          left: -75px;
        }
      }
    }
    .leaf-8 {
      .leaf_text {
        top: 40px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: -75px;
          right: 85px;
        }
      }
    }
    .leaf-9 {
      .leaf_text {
        top: 50px;
        left: 23px;
        .leaf_number {
          position: absolute;
          top: -110px;
          right: 30px;
        }
      }
    }
    .leaf-10 {
      .leaf_text {
        top: 50px;
        left: 21px;
        .leaf_number {
          position: absolute;
          top: -80px;
          right: -28px;
        }
      }
    }
    .leaf-11 {
      .leaf_text {
        top: 40px;
        left: 20px;
        .leaf_number {
          position: absolute;
          top: -40px;
          right: -75px;
        }
      }
    }
    .leaf-12 {
      .leaf_text {
        top: 50px;
        left: 22px;
        .leaf_number {
          position: absolute;
          top: 28px;
          right: -95px;
        }
      }
    }
  }
}

// 13 & 14 Petals flower
.finalizedFlower {
  .flower_leaf_14 {
    .leaf_text {
      width: 70px;
      .leaf_detail {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .leaf_paragraph {
          display: none;
        }
      }
    }
    .leaf-1 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 85px;
          right: -75px;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 125px;
          left: 82px;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 145px;
          right: 21px;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 126px;
          left: -25px;
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 88px;
          left: -66px;
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 40px;
          left: -100px;
        }
      }
    }
    .leaf-7 {
      .leaf_text {
        top: 50px;
        left: 20px;
        .leaf_number {
          position: absolute;
          bottom: 45px;
          left: -103px;
        }
      }
    }
    .leaf-8 {
      .leaf_text {
        top: 50px;
        left: 25px;
        .leaf_number {
          position: absolute;
          top: -68px;
          right: 110px;
        }
      }
    }
    .leaf-9 {
      .leaf_text {
        top: 50px;
        left: 30px;
        .leaf_number {
          position: absolute;
          top: -113px;
          right: 80px;
        }
      }
    }
    .leaf-10 {
      .leaf_text {
        top: 50px;
        left: 25px;
        .leaf_number {
          position: absolute;
          top: -130px;
          right: 24px;
        }
      }
    }
    .leaf-11 {
      .leaf_text {
        top: 40px;
        left: 25px;
        .leaf_number {
          position: absolute;
          top: -121px;
          right: -41px;
        }
      }
    }
    .leaf-12 {
      .leaf_text {
        top: 50px;
        left: 23px;
        .leaf_number {
          position: absolute;
          top: -70px;
          right: -75px;
        }
      }
    }
    .leaf-13 {
      .leaf_text {
        top: 40px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: -27px;
          right: -118px;
        }
      }
    }
    .leaf-14 {
      .leaf_text {
        top: 50px;
        left: 15px;
        .leaf_number {
          position: absolute;
          top: 37px;
          right: -110px;
        }
      }
    }
  }
}

// 15, 16, 17 & 18 Petals flower
.finalizedFlower {
  .flower_leaf_18 {
    .leaf_text {
      width: 150px;
      .leaf_detail {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .leaf_paragraph {
          display: none;
        }
      }
    }
    .leaf-1 {
      .leaf_text {
        top: 50px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: 0px;
          right: -30px;
          transform: rotate(-30deg);
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        top: 50px;
        left: 12px;
        .leaf_number {
          position: absolute;
          top: 0px;
          right: -30px;
          transform: rotate(-50deg);
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        top: 50px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: -1px;
          right: -25px;
          transform: rotate(-70deg);
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        top: 51px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: 0px;
          right: -30px;
          transform: rotate(-90deg);
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        top: 50px;
        left: 11px;
        .leaf_number {
          position: absolute;
          top: 0px;
          right: -24px;
          transform: rotate(-110deg);
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        top: 45px;
        left: 0px;
        .leaf_number {
          position: absolute;
          top: 0px;
          left: -36px;
          transform: rotate(50deg);
        }
      }
    }
    .leaf-7 {
      .leaf_text {
        top: 45px;
        left: 0px;
        .leaf_number {
          position: absolute;
          top: 1px;
          left: -38px;
          transform: rotate(30deg);
        }
      }
    }
    .leaf-8 {
      .leaf_text {
        top: 48px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: -1px;
          left: -28px;
          transform: rotate(10deg);
        }
      }
    }
    .leaf-9 {
      .leaf_text {
        top: 45px;
        left: 0;
        .leaf_number {
          position: absolute;
          top: 1px;
          left: -48px;
          transform: rotate(-10deg);
        }
      }
    }
    .leaf-10 {
      .leaf_text {
        top: 45px;
        left: 0px;
        .leaf_number {
          position: absolute;
          top: 1px;
          left: -35px;
          transform: rotate(-30deg);
        }
      }
    }
    .leaf-11 {
      .leaf_text {
        top: 32px;
        left: -20px;
        .leaf_number {
          position: absolute;
          top: 0;
          left: -43px;
          transform: rotate(-50deg);
        }
      }
    }
    .leaf-12 {
      .leaf_text {
        top: 44px;
        left: 0px;
        .leaf_number {
          position: absolute;
          top: 2px;
          left: -38px;
          transform: rotate(-70deg);
        }
      }
    }
    .leaf-13 {
      .leaf_text {
        top: 43px;
        left: 0;
        .leaf_number {
          position: absolute;
          top: 0px;
          left: -46px;
          transform: rotate(-90deg);
        }
      }
    }
    .leaf-14 {
      .leaf_text {
        top: 45px;
        left: 5px;
        .leaf_number {
          position: absolute;
          top: 0px;
          left: 171px;
          transform: rotate(70deg);
        }
      }
    }
    .leaf-15 {
      .leaf_text {
        top: 47px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: 1px;
          right: -42px;
          transform: rotate(50deg);
        }
      }
    }
    .leaf-16 {
      .leaf_text {
        top: 50px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: -1px;
          right: -42px;
          transform: rotate(30deg);
        }
      }
    }
    .leaf-17 {
      .leaf_text {
        top: 50px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: -2px;
          right: -48px;
          transform: rotate(10deg);
        }
      }
    }
    .leaf-18 {
      .leaf_text {
        top: 50px;
        left: 10px;
        .leaf_number {
          position: absolute;
          top: 0px;
          right: -50px;
          transform: rotate(-10deg);
        }
      }
    }
  }
}

// 19 & 20 Petals flower
// .finalizedFlower {
//   .flower_leaf_20 {
//     .leaf_text {
//       width: 150px;
//       .leaf_detail {
//         height: 20px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         .leaf_paragraph {
//           display: none;
//         }
//       }
//     }
//     .leaf-1 {
//       .leaf_text {
//         top: 50px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           right: -30px;
//           transform: rotate(-30deg);
//         }
//       }
//     }
//     .leaf-2 {
//       .leaf_text {
//         top: 50px;
//         left: 12px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           right: -30px;
//           transform: rotate(-50deg);
//         }
//       }
//     }
//     .leaf-3 {
//       .leaf_text {
//         top: 50px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: -1px;
//           right: -25px;
//           transform: rotate(-70deg);
//         }
//       }
//     }
//     .leaf-4 {
//       .leaf_text {
//         top: 51px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           right: -30px;
//           transform: rotate(-90deg);
//         }
//       }
//     }
//     .leaf-5 {
//       .leaf_text {
//         top: 50px;
//         left: 11px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           right: -24px;
//           transform: rotate(-110deg);
//         }
//       }
//     }
//     .leaf-6 {
//       .leaf_text {
//         top: 45px;
//         left: 0px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           left: -36px;
//           transform: rotate(50deg);
//         }
//       }
//     }
//     .leaf-7 {
//       .leaf_text {
//         top: 45px;
//         left: 0px;
//         .leaf_number {
//           position: absolute;
//           top: 1px;
//           left: -38px;
//           transform: rotate(30deg);
//         }
//       }
//     }
//     .leaf-8 {
//       .leaf_text {
//         top: 48px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: -1px;
//           left: -28px;
//           transform: rotate(10deg);
//         }
//       }
//     }
//     .leaf-9 {
//       .leaf_text {
//         top: 45px;
//         left: 0;
//         .leaf_number {
//           position: absolute;
//           top: 1px;
//           left: -48px;
//           transform: rotate(-10deg);
//         }
//       }
//     }
//     .leaf-10 {
//       .leaf_text {
//         top: 45px;
//         left: 0px;
//         .leaf_number {
//           position: absolute;
//           top: 1px;
//           left: -35px;
//           transform: rotate(-30deg);
//         }
//       }
//     }
//     .leaf-11 {
//       .leaf_text {
//         top: 32px;
//         left: -20px;
//         .leaf_number {
//           position: absolute;
//           top: 0;
//           left: -43px;
//           transform: rotate(-50deg);
//         }
//       }
//     }
//     .leaf-12 {
//       .leaf_text {
//         top: 44px;
//         left: 0px;
//         .leaf_number {
//           position: absolute;
//           top: 2px;
//           left: -38px;
//           transform: rotate(-70deg);
//         }
//       }
//     }
//     .leaf-13 {
//       .leaf_text {
//         top: 43px;
//         left: 0;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           left: -46px;
//           transform: rotate(-90deg);
//         }
//       }
//     }
//     .leaf-14 {
//       .leaf_text {
//         top: 45px;
//         left: 5px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           left: 171px;
//           transform: rotate(70deg);
//         }
//       }
//     }
//     .leaf-15 {
//       .leaf_text {
//         top: 47px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: 1px;
//           right: -42px;
//           transform: rotate(50deg);
//         }
//       }
//     }
//     .leaf-16 {
//       .leaf_text {
//         top: 50px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: -1px;
//           right: -42px;
//           transform: rotate(30deg);
//         }
//       }
//     }
//     .leaf-17 {
//       .leaf_text {
//         top: 50px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: -2px;
//           right: -48px;
//           transform: rotate(10deg);
//         }
//       }
//     }
//     .leaf-18 {
//       .leaf_text {
//         top: 50px;
//         left: 10px;
//         .leaf_number {
//           position: absolute;
//           top: 0px;
//           right: -50px;
//           transform: rotate(-10deg);
//         }
//       }
//     }
//   }
// }


// Flower leaf numbers colors
.finalizedFlower {
  .flower {
    .leaf-1 {
      .leaf_text {
        .leaf_number {
          background-color: #3c68dc;
          color: #fff;
        }
      }
    }
    .leaf-2 {
      .leaf_text {
        .leaf_number {
          background-color: #c84710;
          color: #fff;
        }
      }
    }
    .leaf-3 {
      .leaf_text {
        .leaf_number {
          background-color: #cb2c8b;
          color: #fff;
        }
      }
    }
    .leaf-4 {
      .leaf_text {
        .leaf_number {
          background-color: #8558ea;
          color: #fff;
        }
      }
    }
    .leaf-5 {
      .leaf_text {
        .leaf_number {
          background-color: #047e82;
          color: #fff;
        }
      }
    }
    .leaf-6 {
      .leaf_text {
        .leaf_number {
          background-color: #448009;
          color: #fff;
        }
      }
    }
    .leaf-7 {
      .leaf_text {
        .leaf_number {
          background-color: #a56110;
          color: #fff;
        }
      }
    }
    .leaf-8 {
      .leaf_text {
        .leaf_number {
          background-color: #d33333;
          color: #fff;
        }
      }
    }
    .leaf-9 {
      .leaf_text {
        .leaf_number {
          background-color: #c225b5;
          color: #fff;
        }
      }
    }
    .leaf-10 {
      .leaf_text {
        .leaf_number {
          background-color: #6661ea;
          color: #fff;
        }
      }
    }
    .leaf-11 {
      .leaf_text {
        .leaf_number {
          background-color: #068344;
          color: #fff;
        }
      }
    }
    .leaf-12 {
      .leaf_text {
        .leaf_number {
          background-color: #7f7506;
          color: #fff;
        }
      }
    }
    .leaf-13 {
      .leaf_text {
        .leaf_number {
          background-color: #d73466;
          color: #fff;
        }
      }
    }
    .leaf-14 {
      .leaf_text {
        .leaf_number {
          background-color: #a63cdc;
          color: #fff;
        }
      }
    }
    .leaf-15 {
      .leaf_text {
        .leaf_number {
          background-color: #3c68dc;
          color: #fff;
        }
      }
    }
    .leaf-16 {
      .leaf_text {
        .leaf_number {
          background-color: #098413;
          color: #fff;
        }
      }
    }
    .leaf-17 {
      .leaf_text {
        .leaf_number {
          background-color: #986707;
          color: #fff;
        }
      }
    }
    .leaf-18 {
      .leaf_text {
        .leaf_number {
          background-color: #3c68dc;
          color: #fff;
        }
      }
    }
    .leaf-19 {
      .leaf_text {
        .leaf_number {
          background-color: #c84710;
          color: #fff;
        }
      }
    }
    .leaf-20 {
      .leaf_text {
        .leaf_number {
          background-color: #cb2c8b;
          color: #fff;
        }
      }
    }
    .leaf-21 {
      .leaf_text {
        .leaf_number {
          background-color: #8558EA;
          color: #fff;
        }
      }
    }
    .leaf-22 {
      .leaf_text {
        .leaf_number {
          background-color: #D33333;
          color: #fff;
        }
      }
    }
    .leaf-23 {
      .leaf_text {
        .leaf_number {
          background-color: #C225B5;
          color: #fff;
        }
      }
    }
    .leaf-24 {
      .leaf_text {
        .leaf_number {
          background-color: #068344;
          color: #fff;
        }
      }
    }
    .active .leaf_number {
      color: #070707 !important;
      background-color: #fff !important;
    }
  }
}