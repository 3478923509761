.top_filter {
  display: flex;
  align-items: center;
  .company_actions {
    display: flex;
    gap: 6px;
  }
}

.filter_right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer_logout {
  border: unset;
  padding: unset;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  box-shadow: unset;
  background-color: unset;
  justify-content: flex-start;
  color: #0d1019;
  font-size: 14px;
  line-height: 20px;

  span {
    display: flex;
    gap: 8px;
  }

  &:hover {
    background-color: transparent !important;
    color: #0d1019 !important;
  }
}

.filter_btn {
  display: flex;
  align-items: center;

  button {
    height: 40px;
    border: 0;
    box-shadow: none;
    padding: 0;

    span {
      color: #0d1019;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
    }
  }

  img {
    display: block;
  }
  .ant-select {
    height: 40px;
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #161616;
      font-family: "DM Sans", sans-serif;
    }
    .ant-select-selector {
      border-radius: 10px;
      border: 1px solid #e8e9f0;
      background-color: transparent;
      z-index: 9;
    }
  }
  .filterTitle {
    margin-right: 11px;
    font-size: 16px;
    font-weight: 300;
    color: #696969;
    font-family: "DM Sans", sans-serif;
  }
}

.search_filter {
  width: 400px;
  display: flex;
  position: relative;

  .ant-input-outlined {
    height: 40px;
    background-color: #e8e9f0;
    border-radius: 10px;
    font-size: 16px;
    color: #050622;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    border: 1px solid #e8e9f0;
    transition: all 0.3s;
    &:hover {
      background-color: #d9dbe6;
      border: 1px solid #d9dbe6;
      transition: all 0.3s;
      input {
        &::placeholder {
          color: #7c7e8c;
        }
      }
    }

    input {
      &::placeholder {
        color: #7c7e8c;
        font-weight: 300;
        font-family: "DM Sans", sans-serif;
      }
    }
    .ant-input-prefix{
      margin-inline-end: 8px;
    }
  }

  .no_data_find {
    position: absolute;
    width: 100%;
    top: 42px;
    left: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 6px 6px 24px 0px rgba(40, 35, 28, 0.03);
    border: 1px solid rgba(243, 244, 248, 1);
    z-index: 9;
    padding: 10px 16px;
    line-height: 24px;
  }

  .find_search {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .ant-spin {
      color: #161616;
    }
  }

  .search_dropdown {
    position: absolute;
    width: 100%;
    top: 42px;
    left: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 6px 6px 24px 0px rgba(40, 35, 28, 0.03);
    border: 1px solid rgba(243, 244, 248, 1);
    z-index: 99;
    max-height: 300px;
    overflow-y: auto;
    padding: 4px 4px 8px 4px;

    .search_item {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin: 0;
        width: 100%;
        text-align: left;
        border: 0;
        padding: 6px 12px;
        justify-content: flex-start;
        color: rgba(7, 7, 7, 1);

        &:hover {
          background-color: #f3f4f8;
          color: #070707;
        }
      }

      .label_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .label_module {
          border: 1px solid rgba(239, 240, 245, 1);
          color: rgba(22, 22, 22, 1);
          border-radius: 4px;
          padding: 4px 6px;
          width: 60px;
          height: 18px;
          text-align: center;
          font-size: 10px;
          font-weight: 500;
          line-height: 1;
        }
      }
    }
  }
}

.ant-input-number .ant-input-number-input::placeholder,
.ant-picker .ant-picker-input > input::placeholder {
  color: #727379;
  font-weight: 300;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
}

.ant-select:hover .ant-select-arrow:not(:last-child) {
  opacity: 1;
}

.ant-input-outlined {
  padding: 10px 16px;
  border-radius: 36px;
  border: 1px solid #e8e9f0;

  &:hover {
    border: 1px solid #d9dbe6;
  }
  &:focus,
  &:active,
  &:focus-within {
    box-shadow: unset;
    border: 1px solid #ccceda;
  }

  &.ant-input {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    color: #161616;
  }
}

.icon_dark_btn {
  background-color: #161616;
  border-radius: 10px;
  padding: 10px 20px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #161616;
  box-shadow: unset;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #2b2b2c;
    border: 1px solid #2b2b2c;
    color: #fff;
  }

  &:disabled {
    border: 1px solid #70727d;
    background-color: #70727d;
    color: #ACAEBD;
  }
  .ant-btn-icon {
    .anticon {
      svg {
        width: 19px;
        height: 19px;
        path {
          fill: #fff;
        }
      }
    }
  }
  .angleIcon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.icon_grey_btn {
  background-color: #f3f4f8;
  border-radius: 10px;
  padding: 10px 20px;
  height: 40px;
  color: #0e010f;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #f3f4f8;
  box-shadow: unset;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #e4e6ee;
    border: 1px solid #e4e6ee;
    color: #2b2b2c;
  }

  &:disabled {
    border: 1px solid #f3f4f8;
    background-color: #f3f4f8;
    color: #acaebd;
    .anticon{
      svg{
        path{
          fill: #acaebd;
        }
      }
    }
  }
}

.menu_active{
  background-color: #E8E9F0 !important;
  border: 1px solid #E8E9F0 !important;
  color: #070707 !important;
}

.icon_white_btn {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  height: 40px;
  color: #161616 !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #e8e9f0;
  box-shadow: unset;

  &:not(:disabled):not(.ant-tag):not(.ant-btn-disabled):hover {
    background-color: #E4E6EE;
    border: 1px solid #E4E6EE;
    color: #2B2B2C;
  }

  &:disabled {
    border: 1px solid #F3F4F8;
    background-color: #F3F4F8;
    color: #ACAEBD;
  }
  .ant-btn-icon {
    .anticon {
      svg {
        path {
          fill: #9396a6;
        }
      }
    }
  }
}

button {
  img {
    display: block;
  }
}

.ant-table-wrapper {
  .ant-table {
    border: 1px solid #f3f4f8;
    border-radius: 12px;
    overflow: hidden;
  }

  .ant-table-thead {
    > tr {
      > th {
        background-color: #f7f8fa;
        color: #070707;
        font-size: 14px;
        font-weight: 400;
        font-family: "DM Sans", sans-serif;
        line-height: 20px;
        padding: 12px 16px;
        border-bottom: 1px solid #f3f4f8;

        &::before {
          display: none !important;
        }

        &:first-child {
          border-start-start-radius: 4px !important;
        }

        &:last-child {
          border-start-end-radius: 4px !important;
        }
      }
    }
  }
  
  .ant-table-tbody {
    > tr {
      cursor: pointer;
      > td {
        background-color: #fff;
        color: #070707;
        font-size: 14px;
        font-weight: 300;
        font-family: "DM Sans", sans-serif;
        line-height: 20px;
        padding: 10px 16px;
        border-bottom: 1px solid #f3f4f8;
        text-transform: capitalize;

        &::before {
          display: none !important;
        }

        &:first-child {
          border-start-start-radius: 0px !important;
        }

        &:last-child {
          border-start-end-radius: 0px !important;
        }
      }

      &.ant-table-row-selected {
        > td {
          &:first-child {
            border-start-start-radius: 0 !important;
          }

          &:last-child {
            border-start-end-radius: 0 !important;
          }
        }
      }

      &:last-child {
        > td {
          border-bottom: 0 !important;
        }
      }
    }
    .ant-table-row {
        > .ant-table-cell-row-hover {
          background-color: #F3F4F8;
          border-bottom: 1px solid #F3F4F8;
        }
    }
  }
}

.table_with_image {
  display: flex;
  align-items: center;

  .ant-avatar {
    width: 28px;
    height: 28px;
    border: 1px solid #E8E9F0;
    background-color: #F3F4F8;
    color: #161616;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    margin-right: 8px;
  }

  .ant-image {
    margin-right: 8px;
    img {
      border-radius: 8px;
      object-fit: cover;
    }
  }



  .main_text {
    display: block;
    color: #0d1019;
    font-size: 14px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    line-height: 17px;
  }

  .email_text {
    display: block;
    color: #727379;
    font-size: 10px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    line-height: 15px;
  }
}

.defaultTag {
  padding: 2px 8px;
  border-radius: 6px;
  width: auto;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  line-height: 20px;
  font-size: 14px;
  text-transform: capitalize;

  &.white_tag {
    background-color: #fff;
    border: 1px solid #eff0f5;
    color: #161616 !important;
  }
  &.orange_tag {
    background-color: #fff6e5;
    border-color: #eb9d0666;
    color: #eb9d06;
    .default_badge {
      background-color: #eb9d06;
    }
  }
  &.green_tag {
    background-color: #effdf0;
    border-color: #0d9b5466;
    color: #068344;
    .default_badge {
      background-color: #068344;
    }
  }
  &.yellow_tag {
    background-color: #FEF6E7;
    border-color: #A5611066;
    color: #A56110;
    .default_badge {
      background-color: #A56110;
    }
  }
  &.blue_tag {
    background-color: #f6f3ff;
    border-color: #8558ea66;
    color: #8558ea;
    .default_badge {
      background-color: #8558ea;
    }
  }
  &.light_blue_tag {
    background-color: #F2F6FF;
    border-color: #3C68DC66;
    color: #3C68DC;
    .default_badge {
      background-color: #3C68DC;
    }
  }
  &.grey_tag {
    background-color: #ecedf3;
    border-color: #4c4d5166;
    color: #4c4d51;
    .default_badge {
      background-color: #4c4d51;
    }
  }
  .default_badge {
    margin-top: -2px;
    margin-right: 6px;
  }
}

.ant-avatar {
  &.ant-avatar-square {
    border-radius: 8px;
  }
}

.ant-card-meta-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-card-meta-title {
    color: #1c1c1c;
    // font-family: "DM Sans", sans-serif;
    // font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  .ant-card-meta-description {
    color: #070707;
    font-size: 16px;
    font-family: "DM Sans", sans-serif;
    font-weight: 300;
    .ant-tag {
      border: 1px solid #c287f4;
      border-radius: 44px;
      background-color: #fff;
      padding: 4px 12px;
      color: #727379;
      font-size: 12px;
      line-height: 16px;
      font-family: "DM Sans", sans-serif;
      font-weight: 500;

      span {
        color: #0d1019;
      }
    }
  }
}

.ant-tabs {
  &.ant-tabs-left {
    .ant-tabs-nav {
      width: 297px;
      display: flex;
      flex: none;
      align-items: flex-start;
      background-color: #fff;
      border-radius: 16px;
      padding: 24px;

      .ant-tabs-nav-wrap {
        flex: none;
        align-self: auto;
        width: 100%;

        .ant-tabs-nav-list {
          display: inline-flex;
          align-items: flex-start;
          width: 100%;
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs-tab {
        padding: 10px 12px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        font-family: "DM Sans", sans-serif;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #eff0f5;
        border-radius: 10px;
        margin-bottom: 16px;
        margin-top: 0px !important;
        &:nth-child(1) {
          margin-bottom: 24px;
        }

        + .ant-tabs-tab {
          margin-top: 4px;
        }

        .ant-tabs-tab-btn {
          color: #161616;
        }

        &.ant-tabs-tab-active {
          background-color: #070707;
          border-radius: 10px;
          .ant-tabs-tab-icon {
            .anticon {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }

          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
      .ant-tabs-tab-disabled {
        margin: 0px;
        padding: 0px;
        border: unset;
        margin-bottom: 16px;
      }
    }

    .ant-tabs-content-holder {
      border-left: 0;

      .ant-tabs-tabpane {
        padding-left: 20px;
      }
    }
  }
}

.ant-card {
  .ant-card-bordered {
    border: 1px solid #e4e4e5;
    border-radius: 20px;
  }
}

.fp_layout_inner {
  .cardHead {
    margin: 0 -24px;
    border-bottom: 1px solid #e4e4e5;
    padding: 0 24px 24px 24px;
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      color: #0d1019;
      font-size: 24px;
      line-height: 28px;
      font-weight: 300;
      font-family: "DM Sans", sans-serif;
      margin-bottom: 4px;
    }

    p {
      color: #484b53;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      font-family: "DM Sans", sans-serif;
      margin-bottom: 0;
    }

    .filter_div {
      margin-top: 16px;
      width: 100%;
    }
  }

  .leftCardDiv {
    width: 375px;

    .ant-form-item {
      margin-bottom: 0;
    }

    + .leftCardDiv {
      .ant-form-item {
        margin-top: 8px;
      }
    }
  }
}

.upload_modal_preview {
  .ant-image {
    img {
      object-fit: cover;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

.photoUploader {
  .ant-upload-drag {
    border: unset;
    border-radius: 16px;
    .ant-upload-btn {
      height: 170px !important;
      background-color: #fff;
      border: 1px dashed #ECEDF3;
      border-radius: 16px;
      padding-top: 44px;
      padding-bottom: 38px;
      transition: all .3s;
      &:hover{
        border: 1px dashed #E4E6EE;
        background-color: #F7F8FA;
        transition: all .3s;
      }
    }
  }
  .actionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    .green-btn {
      background-color: #949599;
      border-radius: 24px;
      padding: 10px 16px;
      border: 1px solid #949599;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
      color: #e4e4e5;
      &:hover {
        background-color: #949599;
        border: 1px solid #949599;
        color: #e4e4e5;
      }
    }
    .grey-btn {
      background-color: #fff;
      border-radius: 24px;
      padding: 10px 16px;
      border: 1px solid #e4e4e5;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
      color: #0d1019;
      &:hover {
        background-color: #fff;
        border: 1px solid #e4e4e5;
        color: #0d1019;
      }
    }
  }
  .close_popup {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: flex-start;
    img {
      width: 10px;
      // height: 10px;
    }
  }
}

.password_label {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.ant-modal {
  .ant-modal-content {
    padding: 0px;
    border-radius: 20px;

    .eTableModal {
      .modal_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 24px 16px 24px;
        border-bottom: 1px solid #F3F4F8;

        .title {
          color: #0F0F10;
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          font-family: 'ESRebondGrotesque-Semibold';
          display: flex;
          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-right: 8px;
          }
        }
        .close_icon {
          justify-content: center;
          align-items: center;
          display: flex;
          .ant-btn {
            padding: 0px;
            height: 20px;
            width: 20px;
            align-items: center;
          }
        }
      }
      .modal_content {
        padding: 24px 24px 0 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        h4 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          font-family: "DM Sans", sans-serif;
          color: #161616;
          margin-bottom: 4px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          font-family: "DM Sans", sans-serif;
          color: #161616;

          &.ant-upload-text {
            color: #070707;
            font-family: "DM Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;
          }

          &.ant-upload-hint {
            color: #7C7E8C;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
          }
        }
        // .ant-select-selection-overflow-item {
        //   display: none;
        // }
      }
      .modal_footer {
        padding: 24px;
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        .cancelBtn,
        .actionBtn {
          border-radius: 10px;
          padding: 10px 20px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          font-family: "DM Sans", sans-serif;
          box-shadow: unset;
        }
        .dangerBtnColor {
          background-color: #161616;
          border: 1px solid #161616;
          color: #fff;
        }
        .defaultBtnColor {
          background-color: #F3F4F8;
          border: 1px solid #F3F4F8;
          color: #161616;
        }
      }
    }
  }
}

.requiredInput label {
  &::after {
    content: unset !important;
  }
}

.ant-table-wrapper {
  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: 0;
    white-space: nowrap;
  }

  .ant-table-column-sorter {
    .ant-table-column-sorter-up + .ant-table-column-sorter-down {
      margin-top: -0.1em;
    }
  }
  .anticon{
    svg{
      path{
        fill: #070707;
      }
    }
  }
}

.ant-tag {
  margin-inline-end: 0;
}

.default_badge {
  width: 5.5px;
  height: 5.5px;
  border-radius: 2px;

  &.grey_badge {
    background-color: #484b53;
  }
  &.yellow_badge {
    background-color: #ffd96c;
  }
  &.green_badge {
    background-color: #3ea561;
  }
}

.no_data_card {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .no_data_image {
    margin-bottom: 24px;
  }

  .no_data_content {
    h4 {
      color: #0d1019;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      font-family: "DM Sans", sans-serif;
      margin-bottom: 8px;
    }

    p {
      color: #484b53;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      font-family: "DM Sans", sans-serif;
      margin-bottom: 24px;
    }
  }

  .no_data_app_btn {
    display: flex;
    align-items: center;
    .ant-image {
      padding-left: 12px;
    }
  }
}

.ant-message {
  .ant-message-notice {
    display: flex;
    justify-content: end;

    .ant-message-notice-content {
      background: #0d1019;
      padding: 12px;
      color: #fff;
      border-radius: 8px;

      .ant-message-custom-content {
        > .anticon {
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .toast_text {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      line-height: 20px;
    }

    .toast_wrapper {
      display: flex;
      align-items: center;
    }

    .close_icon {
      background-color: transparent;
      width: 16px;
      height: 16px;
      border: 0;
      margin-left: 12px;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.alertWrapper {
  .ant-alert {
    margin-bottom: 16px;
    padding: 12px 16px;
    border-radius: 12px;

    [class~="ant-alert-close-icon"] [class~="anticon-close"] {
      color: rgba(5, 6, 34, 1);
    }
  }

  [class~="ant-alert-message"] {
    color: #050622;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 0;

    + .ant-alert-description {
      margin-top: 8px;
    }
  }

  [class~="ant-alert-description"] {
    color: rgba(5, 6, 34, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  [class~="ant-alert-with-description"] [class~="ant-alert-icon"] {
    margin-inline-end: 8px;
  }

  [class~="ant-alert-info"] {
    background-color: rgba(241, 241, 255, 1);
    border-color: rgba(241, 241, 255, 1);
  }

  [class~="ant-alert-error"] {
    background-color: rgba(255, 246, 249, 1);
    border-color: rgba(255, 246, 249, 1);
  }

  [class~="ant-alert-success"] {
    background-color: rgba(243, 253, 245, 1);
    border-color: rgba(243, 253, 245, 1);
  }

  [class~="ant-alert-warning"] {
    background-color: rgba(254, 248, 236, 1);
    border-color: rgba(254, 248, 236, 1);
  }
}

.ant-tooltip {
  max-width: 230px;
}



.ant-table-wrapper .ant-table-container {
  z-index: 0;
}

.empty_card {
  min-height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;

  // .ant-empty-image {
  //     height: auto !important;
  //     .anticon {
  //         svg{
  //             width: 40px;
  //             height: 40px;
  //             margin-bottom: 24px;
  //         }
  //     }
  // }

  h4 {
    font-size: 18px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
    line-height: 24px;
    color: #0d1019;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    line-height: 20px;
    color: #484b53;
    margin-bottom: 24px;
  }
}

.ant-breadcrumb {
  ol {
    li {
      display: flex;
      align-items: center;

      a {
        &.ant-breadcrumb-link {
          font-family: "DM Sans", sans-serif;
          color: #aeafb2;
          &:hover {
            background-color: transparent;
            color: #0d1019;
          }
        }
      }
      
      &:last-child {
        .ant-breadcrumb-link {cursor: inherit; color: #161616;}
      }

      .ant-breadcrumb-link {
        display: flex;
        align-items: center;
        height: auto;
        margin-inline: 0;
        font-size: 16px;
        line-height: 24px;
        color: #9396A6;
        font-family: "DM Sans", sans-serif;
        font-weight: 400;

        .anticon {
          margin-right: 8px;
        }
      }
    }
  }
}

.ant-descriptions {
  .ant-descriptions-item-content {
    font-size: 16px;
    line-height: 22px;
    color: #0d1019;
    font-family: "DM Sans", sans-serif;
    font-weight: 300;
    margin-bottom: 0;

    ul p {
      font-size: 16px;
      line-height: 22px;
      color: #0d1019;
      font-family: "DM Sans", sans-serif;
      font-weight: 300;
      margin-bottom: 0;
    }
  }
}

span.red_color {
  color: #6661ea;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover,
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover a {
  color: rgba(5, 6, 34, 1);
  background-color: transparent;
}

.ant-pagination .ant-pagination-next a,
.ant-pagination .ant-pagination-prev a {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination .ant-pagination-next svg path,
.ant-pagination .ant-pagination-prev svg path {
  fill: rgba(5, 6, 34, 1);
}

.ant-pagination .ant-pagination-next.ant-pagination-disabled svg path,
.ant-pagination .ant-pagination-prev.ant-pagination-disabled svg path {
  fill: rgba(80, 81, 100, 1);
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover,
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover a {
  color: rgba(5, 6, 34, 1);
  background-color: transparent;
}

.ant-pagination .ant-pagination-item {
  border: 0;
}

.ant-pagination .ant-pagination-item a {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(106, 107, 138, 1);
  padding: 4px;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #070707;
}

.ant-pagination .ant-pagination-item-active:hover a,
.ant-pagination .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination .ant-pagination-item-active a {
  padding-bottom: 2px !important;
}

.ant-table-column-sorters {
  .ant-table-column-title {
    padding-right: 4px;
  }
}

.ant-alert-with-description .ant-alert-icon {
  margin-inline-end: 8px;
  margin-bottom: auto;
}

.attendee_modal {
  gap: 0 !important;

  h4 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .add_attendee_select {
    .ant-select-selector {
      border: 1px solid #0d1019;
      border-radius: 20px;
      min-height: 40px;
      z-index: 9;
      background-color: transparent;
    }

    &:hover {
      .ant-select-selector {
        border: 1px solid #0d1019;
      }
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #3F3F42;
    border-color: #3F3F42;
    &::after{
      background-color: #3F3F42;
      width: 5px;
      height: 9px;
    }
  }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #3F3F42;
  border-color: transparent;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner {
  border-color: #3F3F42;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #3F3F42;
}

.ant-descriptions {
  .ant-descriptions-item-label {
    &:after {
      display: none;
    }
  }
}

.anticon-check {
  svg {
    path {
      fill: #161616;
    }
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #161616;
  position: fixed;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: 0;
  top: auto;
  inset-inline-start: auto;
  position: static;
  font-size: 60px;
}

.ant-spin-nested-loading .ant-spin-container::after {
  height: 100vh;
  position: fixed;
}
.ant-spin-nested-loading .ant-spin-blur::after {
  opacity: 0.56;
}

.autocomplete-control {
  display: flex;

  .autocomplete-container {
    width: 300px;
  }

  .ant-btn {
    border: 1px solid;
    line-height: 18px;
  }
}

.ant-dropdown-menu-title-content {
  .ant-btn {
    justify-content: flex-start;
  }
}

.ant-select-dropdown-empty {
  .ant-select-item-empty {
    min-width: 92px !important;
    padding: 5px;
  }
}

.ant-select .ant-select-clear {
  z-index: 10;
}

.ant-select {
  .ant-select-selector {
    border: 1px solid #E8E9F0 !important;
  }
  &:not(.ant-select-status-error){
    &:hover {
      .ant-select-selector {
        border: 1px solid #D9DBE6 !important;
      }
    }
  }

  
}

.warning_alert_popup {
  margin-bottom: 16px;

  .ant-alert-warning {
    background: #FEF6E7;
    border: 0;
    padding: 10px 12px;
    border-radius: 10px;
    align-items: flex-start;

    .anticon  {
      color: #EB9D06;
      width: 16px;
      height: 16px;
      margin-right: 9px;
      margin-top: 2px;

      svg {
        width: 100%;
      }
    }

    .ant-alert-message {
      color: #161616;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      b {
        font-weight: 600;
      }
    }
  }
}

.ant-select {
  .ant-select-selector {
    background-color: transparent !important;
    z-index: 9;
  }
}

.ant-select-dropdown {
  z-index: 10;
  border-radius: 12px;
  padding: 8px 4px;
}

.fp_employee_popup {
  .ant-select-item {
    margin-bottom: 4px;
    padding: 6px 12px;

    .role_label {
      padding: 2px 0;
      color: #070707;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-avatar {
  border: 1px solid #e8e9f0;
  background-color: #f3f4f8;
  color: #161616;
  font-size: 11px;
  font-weight: 600;
  line-height: normal;
}

.create_header_btn.icon_dark_btn:disabled {
  background-color: #161616;
  color: #fff;
  border: 1px solid #161616;
  cursor: default;
}