.ant-input-outlined,
.ant-picker-outlined {
  &.formControl {
    border: 1px solid #e8e9f0;
    border-radius: 8px;
    padding: 10px 16px;
    height: 40px;
    width: 100%;

    &::placeholder {
      color: #727379;
      font-size: 16px;
      font-weight: 300;
      font-family: "DM Sans", sans-serif;
      line-height: 20px !important;
    }

    &:focus-visible {
      border: 1px solid #d9dbe6;
      outline: none
    }
  }
}

.ant-select {
  .ant-select-selection-placeholder {
    color: #727379;
    font-size: 16px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    line-height: 20px !important;
  }
  .ant-select-selection-search-input {
    font-size: 16px;
  }
}

.ant-input-password {
  &.formControl {
    input {
      border: 0;
      padding: 0;
      height: auto;

      &::placeholder {
        color: #727379;
        font-size: 16px;
        font-weight: 300;
        font-family: "DM Sans", sans-serif;
        line-height: 20px !important;
      }
    }
  }
  .ant-input-suffix {
    .ant-input-password-icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

textarea {
  &.ant-input-outlined {
    &.formControl {
      height: 124px;
    }
  }
}

.ant-form {
  .ant-form-item:not(.ant-form-item-horizontal) {
    .ant-form-item-label {
      padding-bottom: 2px;
    }
  }
}

.location_image {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .upload_avatar {
    width: 88px;
    height: 88px;
    background-color: #f1f2f2;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    img {
      border-radius: 12px;
      object-fit: cover;
    }
  }

  .format_text {
    color: #4C4D51;
    font-size: 12px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    line-height: 14px;
    margin-top: 12px;
  }
}

.upload_loc_image {
  display: flex;
  align-items: center;
}

.circle_btn {
  width: 40px;
  height: 40px;
  padding: 0;
  background: transparent;
  display: block;
  border: 0;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: transparent;
  }

  img {
    display: block;
  }
}

.memberDetailCard {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 16px;

  .ant-card-body {
    padding: 20px;
  }

  .red_outline_tag {
    margin-left: 10px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
  }

  .ant-tag {
    + .ant-tag {
      margin-left: 4px;
    }
  }
}

.deactivatedCard {
  background-color: #fdf2f2;
  border: 1px solid #fdf2f2;
  border-radius: 16px;

  .ant-card-meta-avatar {
    padding-inline-end: 8px;
    margin-top: -2px;
  }

  .ant-card-body {
    padding: 12px 16px;
  }

  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-size: 16px;
      line-height: 20px;
      // font-weight: 500;
      // font-family: "DM Sans", sans-serif;
      margin-bottom: 4px !important;
    }

    .ant-card-meta-description {
      font-size: 14px;
      line-height: 20px;
      color: #0d1019;
      margin-bottom: 0;
    }
  }
}

.members_detail_left_card {
  background-color: #f8f8f9;
  border: 1px solid #f8f8f9;
  padding: 20px;
  .eventsDetailTitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
    margin-bottom: 12px;
    color: #0d1019;
  }
  .events_host_detail {
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 12px;

    .ant-card-meta-avatar {
      height: 48px;
      width: 48px;
    }
    .ant-card-meta-detail {
      margin-left: 8px;
      position: relative;
      .ant-card-meta-title {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
        // font-weight: 500;
        // font-family: "DM Sans", sans-serif;
        color: #727379;
      }
      .ant-card-meta-description {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        font-family: "DM Sans", sans-serif;
        color: #0d1019;
        text-transform: capitalize;
      }

      .arrow_btn {
        position: absolute;
        right: -8px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40px;
      }
    }
  }
  .ant-card-body {
    padding: 0px;
    .ant-descriptions-header {
      margin-bottom: 20px;
      .ant-descriptions-title {
        font-size: 20px;
        line-height: 24px;
        font-family: "DM Sans", sans-serif;
        font-weight: 500;
      }
    }
    .ant-descriptions-item {
      padding-bottom: 4px;
      .ant-descriptions-item-label {
        color: #a25edc;
      }
      ul {
        padding-left: 20px;
      }
    }
  }
  table {
    tbody {
      tr {
        &:first-child {
          th {
            &:first-child {
              padding-top: 0;
            }
          }
        }
        th {
          &:first-child {
            padding-bottom: 4px !important;
            padding-top: 8px;
          }
        }
      }
    }
  }
}

.ant-form-item {
  .ant-form-item-label {
    > label {
      color: #161616;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      font-family: "DM Sans", sans-serif;

      &::before {
        display: none !important;
      }

      &.ant-form-item-required {
        &::after {
          visibility: visible !important;
          display: inline-block;
          margin-inline-end: 2px;
          color: #6661EA;
          font-size: 16px;
          line-height: 20px;
          font-family: "DM Sans", sans-serif;
          font-weight: 500;
          content: "*";
        }
      }
    }
  }
}

.cancelation_policy_text {
  textarea {
    .formControl {
      color: #0d1019;
      font-size: 14x;
      line-height: 20px;
      font-family: "DM Sans", sans-serif;
    }
  }
}

.add_attendee_select {
  height: auto;

  &.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-inline-end: 70px;
    padding-inline-start: 8px;
    .ant-select-selection-item {
      background-color: #fff;
      border: 1px solid #f1f2f2;
      border-radius: 40px;
    }
  }
}

.add_attendee_select {
  &.ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0;
  }
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-7vs16m).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-7vs16m).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background: #0d1019;
}

.ant-picker-ranges .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-picker-ranges .ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
  background: #0d1019;
}


.option_desc {
  display: block;
  color: #4C4D51;
  font-weight: 300;
  font-size: 12px;
  margin-top: 4px;
  white-space: pre-wrap;
  line-height: 14px;
}

.ant-select-selection-item {
  .option_wrap {
    .option_desc {
      display: none;
    }
  }
}

.role_name {
  position: relative;

  .role_btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
  }
}

.role_title {
  color: #161616;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
  height: 40px;
  display: flex;
  align-items: center;
}