@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@font-face {
  font-family: "ESRebondGrotesque-Semibold";
  src: url("../fonts/ESRebondGrotesque-Semibold.woff") format("woff"),
    url("../fonts/ESRebondGrotesque-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

@import "./login.scss";
@import "./sidebar.scss";
@import "./formItems.scss";
@import "./members.scss";
@import "./events.scss";
@import "./_general.scss";
@import "./fullpageLayout.scss";
@import "./systemSettings.scss";
@import "./responsive.scss";
@import "./_fpGeneral";
@import "./_header";
@import "./_tableviews";
@import "./_flowerView";
@import "./_fpSession";
@import "./_editJob";
@import "./_drawer";
@import "./_flower_leaf_3";
@import "./_flower_leaf_4";
@import "./_flower_leaf_6";
@import "./_flower_leaf_8";
@import "./_flower_leaf_10";
@import "./_flower_leaf_12";
@import "./_flower_leaf_14";
@import "./_flower_leaf_18";
@import "./_flower_leaf_24";
@import "./_kanban";
@import "./_session-flower";
@import "./_fpSessionRole";
@import "./_finalized_flower";
@import "./_tour";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: "ESRebondGrotesque-Semibold";
}

h2 {
  font-family: "ESRebondGrotesque-Semibold";
}

h3 {
  font-family: "ESRebondGrotesque-Semibold";
}

h4 {
  font-family: "ESRebondGrotesque-Semibold";
}

.p-0 {
  padding: 0 !important;
}

.dark_btn {
  background: #161616;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background: #2b2b2c;
  }
  &:disabled {
    border-color: #70727d;
    background: #70727d;
    color: #acaebd;
  }
}
