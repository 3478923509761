.fp_session_role{
    min-height: calc(100vh - 130px);
    height: auto;
    padding-bottom: 88px;
    h4{
        color: #070707;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        margin-bottom: 4px;
    }
    p{
        color: #070707;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    ol{
        margin-bottom: 16px;
        padding-left: 16px;
        li{
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 4px;
        }
    }

    .content_section{
        border: 1px solid #ECEDF3;
        padding: 16px;
        border-radius: 16px;
        margin-bottom: 16px;
        margin-top: 4px;

        .active_state h6 {
            width: 24px;
            height: 24px;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            margin-right: 8px;
        }
    }
    .move_buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .inputFields{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;
        label{
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #070707;
        }

        input {
            padding: 8px 12px;
        }
    }
    .inputRadio{
        width: 100%;
        margin-bottom: 24px;
        margin: 0 -6px 24px -6px;

        .ant-radio-group {
            display: flex;
            flex-direction: column;
        }

        .best_role_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 6px;
            margin-bottom: 6px;
        }

        .best_roles_name {
            width: 100%;
            border: 1px solid #E8E9F0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            padding: 6px 10px;
        }
        .role_input {
            margin-left: 8px;
            width: 100%;

            input {
                border: 1px solid #fff !important;
                padding: 4px;
                height: auto;
                width: 100%;
            }
        }
        .add_role_btn {
            &.top_space {
                margin-top: 22px;
            }

            button {
                padding: 5px 10px;
                height: 32px;
            }
        }
        label{
            margin: 0;           

            &.label {
                width: 100%;
                display: block;
                margin-bottom: 8px;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #070707;
            }
        }

        .finalized_div {
            width: calc(50% - 3px);

            span {
                display: flex;
                align-items: center;
                padding: 0;

                &.ant-radio {
                    display: none;
                }
            }
        }
    }
    .role_description{
        padding-top: 8px;

        h4{
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #161616;
            margin-bottom: 0;
        }
        .ant-btn {
            padding: 3px 4px;
            color: #161616;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            gap: 6px;
            height: auto;
  
            .anticon {
              svg {
                path {
                  fill: currentColor;
                }
              }
            }
          }
        ul{
            list-style-type: none;
            li{
                
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .descriptors_text {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 300;
                    color: #161616;
                    padding-right: 5px;
                }

                .ant-select {
                    .ant-select-selector {
                        background-color: #F3F4F8;
                        border: 0;
                    }
                }
            }
        }

        .role_wrapper {
            padding: 0;
            margin-bottom: 4px;
            margin-left: 3px;
        }

        .role_content {
            padding: 0;
        
            textarea{
              padding: 6px 5px;
              margin-left: 0;
            }
        
            p {
              padding: 6px 5px;
              width: 100%;
            }
          }

          .detail_dropdown {
            margin-left: 8px;

            button {
                width: 24px;
            }
          }
    }

    .purpose_session_area {
        margin-bottom: 16px;

        .textarea_top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;

            label {
                color: #161616;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }

            .generate_btn {
                color: #161616;
                border: 0;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                border: unset;
            }
        }

        textarea {
            border: 1px solid #E8E9F0;
            border-radius: 10px;
            height: 148px;
            resize: none;
        }
    }

    .centered_div{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.copyLinkBtn{
    position: absolute;
    top: 3.5px;
    right: 3.5px;
    background-color: #F3F4F8;
    border: 1px solid #F3F4F8;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #161616;
    border-radius: 100px;
    &:hover{
        background-color: #F3F4F8 !important;
        border: 1px solid #F3F4F8 !important;
        color: #161616 !important;
    }
}


.flower_wrapper {
    .leaf_detail {
        .leaf_paragraph {
            max-height: 60px;
            overflow: hidden;
            color: #161616;
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
        }
    }

    .active {
        .leaf_paragraph {
            color: #fff;
        }
    }
}

.roles_tag_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.generate_btn {
    color: #161616;
    border: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border: unset;
    box-shadow: unset;
    padding: 4px 0px 4px 8px !important;
    span{
        &:hover{
            color: #161616;
        }
    }
    &:hover{
        color: #2B2B2C !important;
    }
}

.final_step {
    &.centered_content {
        display: flex;
        align-items: center;
    }

    .session_flower {
        width: 100%;
    }
}