.session_flower {
  .ant-tabs-nav {
    display: none;
  }

  .flower_wrapper {
    background-color: transparent;
    min-height: 644px;
    position: relative;
    align-items: center;
    padding-top: 0px;
  }
}

.session_fp_card {
  height: 600px;
  border-radius: 20px;

  .ant-card-body {
    height: 100%;    
  }

  .fp_ready_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 100%;

    h3 {
      color: #070707;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    p {
      color: #070707;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .fp_ready_btn {
    margin-top: 24px;
    display: flex;
    gap: 16px;
  }
}

.select_participants {
  .ant-select {
    margin-top: 24px;

    .ant-select-selection-placeholder {
      padding-left: 26px;
    }
  }
  .ant-select-selector {
    border-radius: 8px;
  }
}

.link_card {
  .copy_link_content {
    .info_alert {
      .ant-alert {
        background-color: #EFF0F5;
      }
    }

    .warning_alert {
      .ant-alert {
        background-color: #FFF6E5;
      }
    }

    .submitted_icon {
      .ant-alert {
        background-color: #ECFDF4;
      }
    }
  }
}

.warning_alert {
  &.ant-alert {
    margin-top: 4px;
    width: 100%;
    border: 0;
    background: #fef6e7;
    border-radius: 10px;
    padding: 10px 12px;

    .anticon {
      margin-inline-end: 8px;

      svg {
        width: 18px;
        height: 18px;
        color: #eb9d06;
      }
    }

    .ant-alert-message {
      color: #161616;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.view_session_detail_popup{
  gap: 12px !important;
  .ant-card{
    border-radius: 12px;
    padding: 16px;
    border: 1px solid #EFF0F5;
    .ant-card-body{
      padding: 0px;
      .detail_top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .detail_heading{
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          color: #161616;
        }
        .detail_btn{
          .ant-btn{
            background-color: #F3F4F8;
            border-radius: 8px;
            border: 1px solid #F3F4F8;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #161616;
            svg{
              path{
                fill: #161616;
              }
            }
            &:hover{
              background-color: #E4E6EE;
              border: 1px solid #E4E6EE;
            }
          }
        }
      }
      .detail_info{
        .detail_info_div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 12px;
        
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          .info_value{
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .info_label{
            min-width: 100px;
          }
        }
      }
    }
  }
}

.ant-select-selection-item-content {
  .detail_option {
    .participant_avatar,
    .user_position {
      display: none;
    }
    .user_name {
      margin-left: 0;
    }
  }
}