.invoice_head {
    background-color: #0A0B2D;
    height: 60px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    h3 {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }

    [class~="ant-steps-item-content"],
    [class~="ant-steps-item-tail"] {
        display: none !important;
    }

    [class~="ant-steps-item-icon"] {
        height: 4px !important;
        width: 62px !important;
        margin-inline-start: 0 !important;
        top: 0 !important;

        [class~="ant-steps-icon-dot"] {
            background-color: #A9AAC1 !important;
            transition: none !important;
            &:after {
                display: none;
            }
        }
    }

    [class~="ant-steps-item"] {
        &[class~="ant-steps-item-finish"],
        &[class~="ant-steps-item-process"] {
            [class~="ant-steps-item-icon"] {
                [class~="ant-steps-icon-dot"] {
                    background: linear-gradient(309.03deg, #D61FB1 -17.61%, #2348F5 82.06%) !important;
                }
            }
        }

        &[class~="ant-steps-item-wait"] {
            [class~="ant-steps-icon-dot"] {
                background-color: #A9AAC1 !important;
            }
        }

        &:last-child {
            [class~="ant-steps-item-icon"] {
                margin-inline-end: 0 !important
            }
        }
    }

    [class~="ant-steps"] {
        [class~="ant-steps-item-icon"] {
            [class~="ant-steps-icon"] {
                top: 0 !important;
            }
        }
    }
    
}


.page_center {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    min-height: 100vh;
    margin-top: 60px;
    margin-bottom: 124px;
    position: relative;
    
    .center_all {
      width: 770px;
      padding: 32px;
    }
  
    .center_head {
      padding-bottom: 10px;
  
      h3 {
        color: #0D1019;
        font-size: 24px;
        font-family: "DM Sans", sans-serif;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 4px;
      }
  
      p {
        color: #484B53;
        font-size: 16px;
        font-weight: 300;
        font-family: "DM Sans", sans-serif;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }

  .discard_wrapper {
    background-color: #fff;
    padding: 24px 40px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .discard_next_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F1F2F2;
    padding: 16px 24px;
    border-radius: 16px;
    width: 100%;
    height: 72px;    
    .dn_left{
        .ant-btn{
            background-color: transparent;
            height: 36px;
            padding: 10px 10px 8px 8px;
            color: #0D1019;
            border-radius: 8px;
            line-height: 16px;
            
            .anticon {
                svg {
                    path {
                        fill: #0D1019;
                    }
                }
            }

            &:hover {
                background-color: transparent;
                border: 1px solid #DADAE7;
            }
        }
    }
  }


  .bg_top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .bg_bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }