.ant-tour {
  width: 294px;
  .ant-tour-arrow {
    display: none;
  }
  .ant-tour-inner {
    background-color: #070707;
    border-radius: 10px;
    box-shadow: none;
    color: #fff;

    .ant-tour-close {
      color: #fff;
      right: 12px;
      top: 12px;
      width: 18px;
      height: 18px;

      &:hover {
        color: #fff;
      }
    }

    .ant-tour-header {
      padding: 10px 12px 12px;
      .ant-tour-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    .ant-tour-description {
      padding: 0 12px;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    .ant-tour-footer {
      padding: 6px 12px;
      .ant-tour-buttons {
        button {
          display: none;
        }
      }
    }


  }

  
}