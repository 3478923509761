.flower_leaf_8 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 46px;
  left: 109px;
  transform: rotate(-45deg);
}

.flower_leaf_8 .leaf-2 {
  position: absolute;
  top: 0;
  z-index: 2;
}

.flower_leaf_8 .leaf-3 {
  position: absolute;
  top: 46px;
  left: 333px;
  transform: rotate(45deg);
  z-index: 1;
}

.flower_leaf_8 .leaf-4 {
  position: absolute;
  top: 157px;
  left: 380px;
  transform: rotate(90deg);
  z-index: 1;
}

.flower_leaf_8 .leaf-5 {
  position: absolute;
  top: 267px;
  left: 331px;
  transform: rotate(135deg);
  z-index: 1;
}

.flower_leaf_8 .leaf-6 {
  position: absolute;
  top: 314px;
  left: 220px;
  transform: rotate(180deg);
  z-index: 1;
}

.flower_leaf_8 .leaf-7 {
  position: absolute;
  top: 267px;
  left: 109px;
  transform: rotate(-135deg);
  z-index: 1;
}

.flower_leaf_8 .leaf-8 {
  position: absolute;
  top: 157px;
  left: 59px;
  transform: rotate(-90deg);
  z-index: 1;
}

.flower_leaf_8 {
  .leaf_text {
    p {
      height: 72px;
      width: 130px;
      overflow: hidden;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 70px;
      left: 40px;
      transform: rotate(45deg);
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 60px;
      left: 40px;
      transform: rotate(0deg);
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 60px;
      left: 50px;
      transform: rotate(-45deg);
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 50px;
      left: 30px;
      transform: rotate(-90deg);
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 60px;
      left: 20px;
      transform: rotate(-135deg);
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 60px;
      left: 20px;
      transform: rotate(180deg);
    }
  }
  .leaf-7 {
    .leaf_text {
      top: 60px;
      left: 20px;
      transform: rotate(135deg);
    }
  }
  .leaf-8 {
    .leaf_text {
      top: 80px;
      left: 30px;
      transform: rotate(90deg);
    }
  }
}
