.flower_leaf_4 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 0;
}

.flower_leaf_4 .leaf-2 {
  position: absolute;
  top: 151px;
  right: 36px;
  transform: rotate(90deg);
  z-index: 2;
}

.flower_leaf_4 .leaf-3 {
  position: absolute;
  top: 302px;
  right: 186px;
  transform: rotate(180deg);
  z-index: 1;
}

.flower_leaf_4 .leaf-4 {
  position: absolute;
  top: 151px;
  left: 36px;
  transform: rotate(-90deg);
  z-index: 1;
}

.flower_leaf_4 {
  .leaf_text {
    flex-direction: column;
    p {
      height: 72px;
      overflow: hidden;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 80px;
      left: 70px;
    }
    .leaf_number {
      margin: 20px auto 0;
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 90px;
      left: 70px;
      transform: rotate(-90deg);
      flex-direction: row-reverse;
    }
    .leaf_number {
      margin-right: 20px;
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 85px;
      left: 65px;
      transform: rotate(-180deg);
    }
    .leaf_number {
      margin: 0 auto 10px;
      order: 1;
    }
    .leaf_detail {
      order: 2;
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 85px;
      left: 65px;
      transform: rotate(90deg);
      flex-direction: row;
    }
    .leaf_number {
      margin-left: 20px;
    }
  }
}
