.mainLogin {
  display: flex;
}

.twoColumn {
  display: flex;
  width: 100%;
}

.leftLogin {
  width: 50%;
  height: 100%;
  padding: 24px 24px 0px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #eff0f5;

  .loginWrapper {
    max-width: 100%;
    width: 100%;
    position: relative;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0 100px 105px;
    border-radius: 24px;
  }

  .loginLogo {
    margin-bottom: 40px;
  }

  .loginForm {
    width: 100%;

    h1 {
      color: #16181D;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 8px;
      margin-top: 0;
    }

    p {
      color: #505560;
      font-weight: 300;
      font-family: "DM Sans", sans-serif;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      margin-top: 0;
      max-width: 460px;
    }

    .ant-form {
      .ant-form-item {
        &:nth-child(2) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .forgotPass {
    // margin-top: -20px;
    margin-bottom: 24px;

    a {
      padding: 10px 0px;
      color: #070707;
      font-weight: 600;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
    }
  }

  .cantLogin {
    margin-top: 24px;

    p {
      color: #9396A6;
      font-weight: 300;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }

    a {
      color: #070707;
      text-decoration: underline;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
    }
  }
  .loginBtn {
    color: #fff;
    background: #070707;
    box-shadow: unset;
    padding: 10px 16px;
    border-radius: 10px;
    height: 40px;
    min-width: 100px;
    font-size: 16px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
    &:hover {
      background-color: #0d1019 !important;
      color: #fff !important;
    }
  }
  .loginFooter{
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 30px;
    padding-top: 15px;
    width: 100%;
    border-top: 1px solid #E8E9F0;
    padding-left: 100px;
  }
}

.rightLogin {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #eff0f5;
  padding: 0 85px;
  background-position: top right;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .right_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .loginVector {
    position: absolute;
    left: 277px;
    width: 975px;
    height: 975px;
    object-fit: cover;
  }

  .loginImage {
    .ant-image {
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}

.quoteWrapper {
  padding-top: 50px;
  max-width: 516px;
  display: flex;

  .quoteText {
    h3 {
      color: #0d1019;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
      font-size: 24px;
      margin-bottom: 16px;
      margin-top: 0;
    }

    p {
      color: #484b53;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
      font-size: 18px;
    }
  }
}