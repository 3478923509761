.flower_leaf_10 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 108px;
  left: 102px;
  transform: rotate(-72deg);
}

.flower_leaf_10 .leaf-2 {
  position: absolute;
  top: 30px;
  left: 159px;
  transform: rotate(-36deg);
  z-index: 2;
}

.flower_leaf_10 .leaf-3 {
  position: absolute;
  top: 0;
  z-index: 1;
}

.flower_leaf_10 .leaf-4 {
  position: absolute;
  top: 30px;
  left: 341px;
  transform: rotate(36deg);
  z-index: 1;
}

.flower_leaf_10 .leaf-5 {
  position: absolute;
  top: 108px;
  left: 395px;
  transform: rotate(72deg);
  z-index: 1;
}

.flower_leaf_10 .leaf-6 {
  position: absolute;
  top: 204px;
  left: 396px;
  transform: rotate(108deg);
  z-index: 1;
}

.flower_leaf_10 .leaf-7 {
  position: absolute;
  top: 282px;
  left: 341px;
  transform: rotate(144deg);
  z-index: 1;
}

.flower_leaf_10 .leaf-8 {
  position: absolute;
  top: 313px;
  left: 250px;
  transform: rotate(180deg);
  z-index: 1;
}

.flower_leaf_10 .leaf-9 {
  position: absolute;
  top: 281px;
  left: 163px;
  transform: rotate(-145deg);
  z-index: 1;
}

.flower_leaf_10 .leaf-10 {
  position: absolute;
  top: 204px;
  left: 107px;
  transform: rotate(-109deg);
  z-index: 1;
}

.flower_leaf_10 {
  .leaf_text {
    p {
      height: 82px;
      width: 90px;
      overflow: hidden;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 55px;
      left: 0px;
      transform: rotate(70deg);
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 50px;
      left: 20px;
      transform: rotate(35deg);
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 50px;
      left: 30px;
      transform: rotate(0deg);
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 20px;
      left: 30px;
      transform: rotate(-35deg);
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 10px;
      left: 20px;
      transform: rotate(-70deg);
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 10px;
      left: -5px;
      transform: rotate(-107deg);
    }
  }
  .leaf-7 {
    .leaf_text {
      top: 20px;
      left: -15px;
      transform: rotate(-145deg);
    }
  }
  .leaf-8 {
    .leaf_text {
      top: 30px;
      left: -25px;
      transform: rotate(180deg);
    }
  }
  .leaf-9 {
    .leaf_text {
      top: 40px;
      left: -25px;
      transform: rotate(145deg);
    }
  }
  .leaf-10 {
    .leaf_text {
      top: 60px;
      left: -15px;
      transform: rotate(110deg);
    }
  }
}
