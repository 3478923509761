.flower_leaf_20 .leaf-1 {
  z-index: 3;
  position: absolute;
  top: 82px;
  left: 110px;
  transform: rotate(-60deg);
}

.flower_leaf_20 .leaf-2 {
  position: absolute;
  top: 53px;
  left: 133px;
  transform: rotate(-45deg);
  z-index: 2;
}

.flower_leaf_20 .leaf-3 {
  position: absolute;
  top: 30px;
  left: 163px;
  transform: rotate(-30deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-4 {
  position: absolute;
  top: 17px;
  left: 199px;
  transform: rotate(-15deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-5 {
  position: absolute;
  top: 12px;
  left: 237px;
  transform: rotate(0deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-6 {
  position: absolute;
  top: 17px;
  left: 275px;
  transform: rotate(15deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-7 {
  position: absolute;
  top: 32px;
  left: 310px;
  transform: rotate(30deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-8 {
  position: absolute;
  top: 53px;
  left: 341px;
  transform: rotate(45deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-9 {
  position: absolute;
  top: 85px;
  left: 361px;
  transform: rotate(60deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-10 {
  position: absolute;
  top: 119px;
  left: 377px;
  transform: rotate(-285deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-11 {
  position: absolute;
  top: 156px;
  left: 383px;
  transform: rotate(90deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-12 {
  position: absolute;
  top: 193px;
  left: 379px;
  transform: rotate(105deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-13 {
  position: absolute;
  top: 228px;
  left: 365px;
  transform: rotate(120deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-14 {
  position: absolute;
  top: 257px;
  left: 341px;
  transform: rotate(135deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-15 {
  position: absolute;
  top: 285px;
  left: 315px;
  transform: rotate(150deg);
  z-index: 1;
}

.flower_leaf_20 .leaf-16 {
  position: absolute;
  top: 293px;
  left: 278px;
  transform: rotate(-195deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-17 {
  position: absolute;
  top: 300px;
  left: 241px;
  transform: rotate(-180deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-18 {
  position: absolute;
  top: 292px;
  left: 204px;
  transform: rotate(195deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-19 {
  position: absolute;
  top: 279px;
  left: 170px;
  transform: rotate(211deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-20 {
  position: absolute;
  top: 253px;
  left: 144px;
  transform: rotate(-133deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-21 {
  position: absolute;
  top: 226px;
  left: 116px;
  transform: rotate(-118deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-22 {
  position: absolute;
  top: 191px;
  left: 102px;
  transform: rotate(-103deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-23 {
  position: absolute;
  top: 154px;
  left: 101px;
  transform: rotate(-88deg);
  z-index: 1;
}
.flower_leaf_20 .leaf-24 {
  position: absolute;
  top: 116px;
  left: 103px;
  transform: rotate(-73deg);
  z-index: 1;
}

.flower_leaf_20 {
  .leaf_text {
    width: 125px;
    height: 20px;
    p {
      display: none;
    }
    h4 {
      font-size: 12px;
      width: max-content;
    }
    .leaf_detail{
      height: auto;
      flex-wrap: nowrap;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .leaf_paragraph{
        display: none;
      }
    }
    .leaf_number{
      height: 16px;
      width: 16px;
      padding: 0px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }
  }

  .leaf-1 {
    .leaf_text {
      top: 70px;
      left: -31px;
      transform: rotate(90deg);
      .leaf_number{
        right: -60px;
        top: 3px;
        transform: rotate(-30deg);
      }
    }
  }

  .leaf-2 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(90deg);
      .leaf_number{
        right: -59px;
        top: 2px;
        transform: rotate(-46deg);
      }
    }
  }

  .leaf-3 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(90deg);
      .leaf_number{
        right: -60px;
        top: 1px;
        transform: rotate(-60deg);
      }
    }
  }

  .leaf-4 {
    .leaf_text {
      top: 70px;
      left: -33px;
      transform: rotate(87deg);
      .leaf_number{
        right: -64px;
        top: 6px;
        transform: rotate(-73deg);
      }
    }
  }

  .leaf-5 {
    .leaf_text {
      top: 70px;
      left: -35px;
      transform: rotate(90deg);
      .leaf_number{
        right: -66px;
        top: -1px;
        transform: rotate(-90deg);
      }
    }
  }

  .leaf-6 {
    .leaf_text {
      top: 70px;
      left: -35px;
      transform: rotate(450deg);
      .leaf_number{
        right: -63px;
        top: -1px;
        transform: rotate(-104deg);
      }
    }
  }
  .leaf-7 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 165px;
        top: 2px;
        transform: rotate(62deg);
      }
    }
  }
  .leaf-8 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 164px;
        top: 2px;
        transform: rotate(45deg);
      }
    }
  }
  .leaf-9 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 159px;
        top: 2px;
        transform: rotate(27deg);
      }
    }
  }
  .leaf-10 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 165px;
        top: 2px;
        transform: rotate(14deg);
      }
    }
  }
  .leaf-11 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 169px;
        top: 2px;
        transform: rotate(0deg);
      }
    }
  }
  .leaf-12 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 167px;
        top: 2px;
        transform: rotate(-15deg);
      }
    }
  }
  .leaf-13 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 161px;
        top: 2px;
        transform: rotate(-29deg);
      }
    }
  }
  .leaf-14 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 155px;
        top: 2px;
        transform: rotate(-47deg);
      }
    }
  }
  .leaf-15 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 162px;
        top: 2px;
        transform: rotate(-62deg);
      }
    }
  }
  .leaf-16 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 162px;
        top: 2px;
        transform: rotate(-78deg);
      }
    }
  }
  .leaf-17 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(270deg);
      display: flex !important;
      justify-content: end;
      .leaf_number{
        right: 167px;
        top: 2px;
        transform: rotate(-89deg);
      }
    }
  }
  .leaf-18 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -51px;
        top: 3px;
        transform: rotate(77deg);
      }
    }
  }
  .leaf-19 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -48px;
        top: 2px;
        transform: rotate(60deg);
      }
    }
  }
  .leaf-20 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -42px;
        top: 2px;
        transform: rotate(40deg);
      }
    }
  }
  .leaf-21 {
    .leaf_text {
      top: 70px;
      left: -32px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -48px;
        top: 1px;
        transform: rotate(28deg);
      }
    }
  }
  .leaf-22 {
    .leaf_text {
      top: 70px;
      left: -31px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -56px;
        top: 1px;
        transform: rotate(13deg);
      }
    }
  }
  .leaf-23 {
    .leaf_text {
      top: 70px;
      left: -31px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -56px;
        top: 2px;
        transform: rotate(-2deg);
      }
    }
  }
  .leaf-24 {
    .leaf_text {
      top: 70px;
      left: -31px;
      transform: rotate(90deg);
      display: flex !important;
      justify-content: flex-start;
      .leaf_number{
        right: -56px;
        top: 3px;
        transform: rotate(-16deg);
      }
    }
  }
}

.job_detail_flower_20{
  .flower.flower_leaf_20 .flower_position {
    left: -100px;
  }
}