@media screen and (max-width: 1399px) {
  .leftLogin {
    padding: 20px;
    width: 50%;
    .loginWrapper {
      padding: 0 40px 0;
      .loginFooter {
        padding-left: 50px;
      }
    }

    .loginForm {
      h1 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 2px;
      }

      p {
        margin-bottom: 10px;
        font-size: 14px;
      }

      .ant-input-outlined.formControl,
      .ant-picker-outlined.formControl {
        height: 35px;
      }

      .ant-input-password.formControl {
        input {
          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }

    .loginBtn {
      height: 35px;
    }

    .loginLogo {
      .ant-image {
        img {
          height: 40px !important;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .loginLogo {
      margin-bottom: 12px;
    }

    .forgotPass {
      margin-bottom: 4px;
    }

    .loginFooter {
      display: none;
    }
  }
  .rightLogin {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  .eventsLeftCard {
    width: 30%;
    flex: none !important;
  }

  .eventsRightCard {
    width: 70%;
    flex: none !important;
  }

  .icon_dark_btn {
    height: 40px;
  }

  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      font-size: 13px;
    }
  }

  .defaultTag {
    font-size: 12px !important;
  }

  .memberDetailLeft {
    flex: 0 0 320px !important;
  }

  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-size: 20px;
      text-transform: capitalize;
    }
  }

  .members_detail_left_card {
    .ant-card-body {
      .ant-descriptions-header {
        .ant-descriptions-title {
          font-size: 18px;
        }
      }
    }
  }

  .member_company_detail {
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 13px;
    }
  }

  .no_data_card {
    .no_data_image {
      margin-bottom: 10px;

      img {
        width: 160px;
      }
    }

    .no_data_content {
      h4 {
        font-size: 18px;
      }
    }
  }

  .eventCard {
    .event_details {
      h4 {
        a {
          font-size: 16px;
        }
      }
    }

    .event_amount {
      font-size: 16px;
    }

    .event_date {
      .dateFld {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .event_details {
      .event_loc_cat {
        .ant-tag {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  .eventCard.upcoming {
    .event_date {
      .dateFld {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .fp_layout_inner {
    .cardHead {
      h3 {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .ant-tabs.ant-tabs-left {
    .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 8px 10px;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  .ant-tabs.ant-tabs-left {
    .ant-tabs-nav {
      width: 140px;
    }
  }
  .table_with_image {
    .main_text {
      font-size: 13px;
      text-transform: capitalize;
    }
  }
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      padding: 10px;
    }
  }

  // FP Responsive css
  .flowerArea {
    max-width: 60%;
    width: 60%;
    flex: 0 0 60%;
  }
  .flowerDetailArea {
    max-width: 40%;
    width: 40%;
    flex: 0 0 40%;
  }
  .finalizedFlower {
    height: 100% !important;
    .flower_position {
      position: absolute;
      left: -5px;
      right: 0;
      top: 0;
      .flower_leaf_8 {
        .leaf-2 {
          position: absolute;
          top: 0;
          left: 221px;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .rightLogin {
    display: none !important;
  }

  .leftLogin {
    width: 100%;
    padding: 16px;
    .loginWrapper {
      border-radius: 16px;
      justify-content: flex-start;
      padding: 20px;
      .ant-form-item {
        .ant-form-item-label > label {
          margin-bottom: 4px !important;
          line-height: 22px;
        }
      }
    }
    .loginForm {
      h1 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 4px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        max-width: 100%;
      }
      .ant-input-outlined.formControl,
      .ant-picker-outlined.formControl {
        height: 40px;
        &::placeholder {
          color: #9396a6;
          font-size: 16px;
          font-weight: 300;
          font-family: "DM Sans", sans-serif;
          line-height: 24px !important;
        }
      }
      .ant-input-password.formControl {
        input {
          &::placeholder {
            color: #9396a6;
            font-size: 16px;
            font-weight: 300;
            font-family: "DM Sans", sans-serif;
            line-height: 24px !important;
          }
        }
      }
    }

    .loginLogo {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e8e9f0;
    }
    .ant-form-item:nth-child(1) {
      margin-bottom: 16px;
    }
    .ant-form-item:nth-child(2) {
      margin-bottom: 20px !important;
    }
    .forgotPass {
      margin-bottom: 16px;
      a {
        padding: 4px;
      }
    }
    .loginBtn {
      height: 40px;
      width: 100%;
    }
    .cantLogin {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 20px;
      line-height: 20px;
      font-size: 14px;
      p{
        margin-bottom: 0px;
      }
    }
  }

  .main_layout {
    .top_header_links, 
    .search_filter{
      display: none;
    }
    .content_area{
      border-radius: 0px;
    }
    .ant-table-content{
      overflow: auto;
    }
    .fp_main_details{
      align-items: flex-start !important;
      .companyInfo{
        .ant-card-meta{
          gap: 10px !important;
        }
        .ant-card-meta-avatar{
          .ant-image{
            width: 50px !important;
            height: 50px !important;
          }
        }
        .ant-card-meta-detail{
          .ant-card-meta-title{
            line-height: normal;
            font-size: 18px;
          }
        }
      }
      .actionBtn{
        gap: 5px !important;
        .companyDeleteBtn {
          padding: 15px;
        }
        .icon_dark_btn{
          padding: 10px 15px;
          font-size: 14px;
          .ant-btn-icon{
            svg{
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .invoice_filter {
      .filter_right{
        display: none;
      }
      .company_actions{
        button{
          padding: 10px !important;
        }
      }
    }
  }

  .content_area {
    .fp_layout_content {
      height: auto !important;
      .ant-card-body{
        padding-top: 24px;
      }
    }
    .fp_layout_title {
      .mainTitle{
        h3{
          font-size: 16px !important;
          font-weight: 600 !important;
          line-height: 20px !important;
        }
      }
    }
  }
  .session_title{
    .icon_grey_btn{
      padding: 10px 15px;
      font-size: 14px;
    }
    .session_head_left{
      h3{
        display: none;
      }
    }
  }
  .shareLink{
    flex-flow: column;
    gap: 20px;
    .col_1, 
    .col_2{
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
    }
    .col_1{
      order: 2;
    }
    .col_2{
      order: 1;
      .status_card_step2{
        height: auto;
        margin-bottom: 20px;
      }
      .step2_role{
        width: 100%;
      }
    }
  } 
  .book_delete {
    display: block !important;
  }  
  .addBook_wrapper:hover .book_delete {
    display: block !important;
  }
  .book_name{
    .ant-input-outlined{
      padding-right: 35px !important;
    }
  }

  .link_card {
    .copy_link_content {
      .ant-alert{
        position: inherit !important;
        margin-top: 50px;
        margin-bottom: 25px;
      }
    }
  }
  .steps_footer{
    position: inherit !important;
  }
}